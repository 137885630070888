import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/MyPracticeTest.css";
import { Link, useNavigate } from "react-router-dom";
function ViewSolution() {
  return (
    <div className="activity_Wrapper">
      <div className="container-fluid">
        <div className="activity_Tab">
          <div className="col-md-12 d-flex">
            <div className="col-md-3 d-block">
              <div className="activity_left_tab">
                <p>Math activity</p>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="Activity_Image">
                <img src="\PrimeStudentImages\parrot.svg" alt="" />
              </div>
              <div className="Activity_chapter_title d-flex">
                <button>1.1 Addition upto 20 Numbers</button>
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-end">
              <div className="Activity_close_icon">
                <Link to="/practicetest">
                  {" "}
                  <img src="\PrimeStudentImages\Close_Icon.svg" alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="activity_view_Solution">
          <div className="view_Solution_wrapper">
            <div className="col-md-12 d-flex">
              <div className="col-md-6">
                <div className="view_Solution_left d-flex">
                  <table>
                    <tr>
                      <td className="d-flex">
                        <div className="view_Solution_Circle">
                          <h5>
                            <span>a.</span>
                          </h5>
                        </div>
                        <div className="view_Solution_header">
                          <h5>
                            <span>3</span>
                            <span>+</span>
                            <span>5</span>
                            <span>=</span>
                            <input value="8" />
                          </h5>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="view_Solution_left d-flex">
                  <table>
                    <tr>
                      <td className="d-flex">
                        <div className="view_Solution_Circle">
                          <h5>
                            <span>b.</span>
                          </h5>
                        </div>
                        <div className="view_Solution_header">
                          <h5>
                            <span>4</span>
                            <span>+</span>
                            <span>4</span>
                            <span>=</span>
                            <input value="8" />
                          </h5>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="view_Solution_left d-flex">
                  <table>
                    <tr>
                      <td className="d-flex">
                        <div className="view_Solution_Circle">
                          <h5>
                            <span>c.</span>
                          </h5>
                        </div>
                        <div className="view_Solution_header">
                          <h5>
                            <span>4</span>
                            <span>+</span>
                            <span>4</span>
                            <span>=</span>
                            <input value="8" />
                          </h5>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="view_Solution_left d-flex">
                  <table>
                    <tr>
                      <td className="d-flex">
                        <div className="view_Solution_Circle">
                          <h5>
                            <span>d.</span>
                          </h5>
                        </div>
                        <div className="view_Solution_header">
                          <h5>
                            <span>4</span>
                            <span>+</span>
                            <span>4</span>
                            <span>=</span>
                            <input value="8" />
                          </h5>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="view_Solution_left d-flex">
                  <table>
                    <tr>
                      <td className="d-flex">
                        <div className="view_Solution_Circle">
                          <h5>
                            <span>e.</span>
                          </h5>
                        </div>
                        <div className="view_Solution_header">
                          <h5>
                            <span>4</span>
                            <span>+</span>
                            <span>4</span>
                            <span>=</span>
                            <input value="8" />
                          </h5>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="view_Solution_left d-flex">
                  <table>
                    <tr>
                      <td className="d-flex">
                        <div className="view_Solution_Circle">
                          <h5>
                            <span>f.</span>
                          </h5>
                        </div>
                        <div className="view_Solution_header">
                          <h5>
                            <span>4</span>
                            <span>+</span>
                            <span>4</span>
                            <span>=</span>
                            <input value="8" />
                          </h5>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="view_Solution_left d-flex">
                  <table>
                    <tr>
                      <td className="d-flex">
                        <div className="view_Solution_Circle">
                          <h5>
                            <span>g.</span>
                          </h5>
                        </div>
                        <div className="view_Solution_header">
                          <h5>
                            <span>4</span>
                            <span>+</span>
                            <span>4</span>
                            <span>=</span>
                            <input value="8" />
                          </h5>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-md-6">
                <div className="View_Solution_Right">
                  <div>
                    <h5>Note:</h5>
                    <p className="d-flex">
                      <li></li>Sum of two positive number is always positive.
                    </p>
                    <p className="d-flex">
                      <li></li>Sum of two positive number is always positive.
                    </p>
                    <p className="d-flex">
                      <li></li>Sum of two positive number is always positive.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Activity_bottom">
            <span>SqillUp Math: Addition upto 20 numbers</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewSolution;
