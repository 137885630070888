import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";
import Thermometer from "react-thermometer-component";
import { Link } from "react-router-dom";
import HomeLeft from "../Pages/HomeLeft";
import {
  studentGreetings,
  studentNotice,
  dashboardCardScore,
} from "../Services";
import { Loaders } from "../Helpers/Loaders";
function Home() {
  const [stuGreetings, setStuGreetings] = useState("");
  const [stunotice, setStuNotice] = useState("");
  const [loading, setLoading] = useState(false);
  const [cardScore, setCardScore] = useState("");
  useEffect(() => {
    studentGreetings().then((greetings) => {
      setStuGreetings(greetings);
    });
    studentNotice().then((notice) => {
      setStuNotice(notice);
    });
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    dashboardCardScore().then((score) => {
      setCardScore(score);
    });
  }, []);
  return (
    <>
      <div className="Content_Home_Wrapper">
        <div className="container-fluid">
          {loading ? (
            <div className="loader-container">
              <div className="spinner">{/* <Loaders /> */}</div>
            </div>
          ) : (
            <div className="Home_page_Wrapper">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-8 col-xs-4 d-flex">
                  <div className="col-lg-8 col-md-8 col-sm-4 col-xs-4">
                    <div className="Greetings">
                      <h5>
                        {stuGreetings.greeting}! <b>{stuGreetings.name}</b>
                      </h5>
                    </div>
                    <div className="Daily_Notifications_Image">
                      <img
                        src="\PrimeStudentImages\Daily_Notifications_Icon.jpg"
                        alt=""
                      />
                      <div className="Notices_home">
                        <h4>{stunotice[0]?.title}</h4>
                        <div className="col-md-5">
                          <p>{stunotice[0]?.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="ProgressBar_ScoreBoard d-flex">
                      <div className="col-md-7">
                        <div className="Progressbar d-flex">
                          <div className="col-md-6 ">
                            <h6>Progress bar</h6>
                          </div>
                          <div className="col-md-6 d-md-flex justify-content-md-end">
                            <Link to="/progressbar">
                              <button>View all</button>
                            </Link>
                          </div>
                        </div>
                        <div className="Progress_bars">
                          <div className="Thermometer_Progressbar d-flex">
                            <div className="Thermometer_Maths">
                              <Thermometer
                                theme="light"
                                value="18"
                                max="100"
                                steps="3"
                                format="°C"
                                size="large"
                                height="230"
                              />

                              <p>Maths</p>
                            </div>
                            <div className="Thermometer_Science">
                              <Thermometer
                                theme="light"
                                value="18"
                                max="100"
                                steps="3"
                                format="°C"
                                size="large"
                                height="230"
                              />
                              <p>Science</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="ScoreBoard d-flex">
                          <div className="col-md-6">
                            <h6>Score</h6>
                          </div>
                          <div className="col-md-6 d-md-flex justify-content-md-end">
                            <Link to="/scoreview">
                              <button>View all</button>
                            </Link>
                          </div>
                        </div>
                        {cardScore.length &&
                          cardScore.map((score, index) => (
                            <div className="Score_Boards_Subject">
                              <div className="card">
                                <div className="card-body">
                                  <div className="card-title">
                                    <div className="card-text d-flex">
                                      <h6>{score?.subject?.name}</h6>
                                      <p>
                                        <span>
                                          Average
                                          <br />
                                          <n style={{ paddingLeft: "0.8rem" }}>
                                            {score?.avg}%
                                          </n>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        {/* <div className="Maths_Score_Board">
                            <img
                              src="\PrimeStudentImages\Maths_Scoreboard_Icon.svg"
                              alt=""
                            />
                            <div className="Maths_Average d-flex">
                              <h6>Maths</h6>
                              <img
                                src="\PrimeStudentImages\Math_circle_icon.svg"
                                alt=""
                              />
                              <p>
                                Average
                                <br />
                                <n>78.6%</n>
                              </p>
                            </div>
                          </div>
                          <div className="Science_Score_Board">
                            <img
                              src="\PrimeStudentImages\Science_Scoreboard_Icon.svg"
                              alt=""
                            />
                            <div className="Science_Average d-flex">
                              <h6>Maths</h6>
                              <img
                                src="\PrimeStudentImages\Science_circle_icon.svg"
                                alt=""
                              />
                              <p>
                                Average
                                <br />
                                <n>78.6%</n>
                              </p>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </div>
                  <div classname="col-md-4">
                    <HomeLeft />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Home;
