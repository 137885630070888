import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";
import { AiFillLock } from "react-icons/ai";
function BadgesRewards() {
  return (
    <div className="rewards_badges_wrapper">
      <div className="container-fluid">
        <div className="col-md-12 d-flex">
          <div className="col-md-8">
            {/* <Scrollbars vertical autoHide={false} style={scrollBarStyle}> */}
            <div className="reward_left_side  d-flex">
              <div className="col-md-6">
                <h5>Available badges</h5>
              </div>
              <div className="col-md-6">
                <p>View all</p>
              </div>
            </div>
            <div className="rewards_images d-flex">
              <div className="col-md-2">
                <i>
                  <AiFillLock />
                </i>
                <img src="\PrimeStudentImages\walk_icon.svg" alt="" />
              </div>
              <div className="col-md-2">
                <i>
                  <AiFillLock />
                </i>
                <img src="\PrimeStudentImages\reading_icon.svg" alt="" />
              </div>
              <div className="col-md-2">
                <i>
                  <AiFillLock />
                </i>
                <img src="\PrimeStudentImages\joystick_icon.svg" alt="" />
              </div>
              <div className="col-md-2">
                <i>
                  <AiFillLock />
                </i>
                <img src="\PrimeStudentImages\bycicle_icon.svg" alt="" />
              </div>
              <div className="col-md-2">
                <i>
                  <AiFillLock />
                </i>
                <img src="\PrimeStudentImages\television_icon.svg" alt="" />
              </div>
            </div>
            <div className="reward_left_bottom_side  d-flex">
              <div className="col-md-6">
                <h5>My collection</h5>
              </div>
              <div className="col-md-6">
                <p>View all</p>
              </div>
            </div>
            <div className="rewards_images_left_bottom d-flex">
              <div className="col-md-2">
                <img src="\PrimeStudentImages\walk_icon.svg" alt="" />
              </div>
              <div className="col-md-2">
                <img src="\PrimeStudentImages\reading_icon.svg" alt="" />
              </div>
              <div className="col-md-2">
                <img src="\PrimeStudentImages\joystick_icon.svg" alt="" />
              </div>
              <div className="col-md-2">
                <img src="\PrimeStudentImages\bycicle_icon.svg" alt="" />
              </div>
              <div className="col-md-2">
                <img src="\PrimeStudentImages\television_icon.svg" alt="" />
              </div>
            </div>
            {/* </Scrollbars> */}
          </div>
          <div className="col-md-4">
            <div className="right-badges">
              <div className="right-image">
                <img src="\PrimeStudentImages\Group 10711@2x.png" alt="" />
              </div>
              <div className="badges-paragraph1">
                <p>TV TIME</p>
              </div>
              <div className="badges-paragraph2">
                <p>EARNED BADGE +75 IN MATH SELFTEST</p>
              </div>
              <div className="badges-paragraph3">
                <hr></hr>
                <div>
                  <p>ADD TO COLLECTION</p>
                </div>
                <hr></hr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BadgesRewards;
