import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/MyPracticeTest.css";
import { MdAccessTimeFilled } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Sounds from "../LottieJsonFiles/Sounds.mp3";
function Activity() {
  const [hide, setHide] = useState(false);
  const [submit, setSubmit] = useState(false);
  const activateNext = () => {
    setHide(true);
  };
  const activateBack = () => {
    setHide(false);
  };
  const openSubmitModal = (e) => {
    setSubmit(true);
  };
  const playAudio = () => {
    new Audio(Sounds).play();
  };
  const submitBack = () => {
    setSubmit(false);
  };
  return (
    <>
      <div className="activity_Wrapper">
        <div className="container-fluid">
          <div className="activity_Tab">
            <div className="col-md-12 d-flex">
              <div className="col-md-3 d-block">
                <div className="activity_left_tab">
                  <p>Math activity</p>
                  <p>No of question = 12</p>
                </div>
              </div>
              <div className="col-md-6 d-flex">
                <div className="Activity_Image">
                  <img src="\PrimeStudentImages\parrot.svg" alt="" />
                </div>
                <div className="Activity_chapter_title d-flex">
                  <button>1.1 Addition upto 20 Numbers</button>
                </div>
              </div>
              <div className="col-md-3 d-flex justify-content-end">
                <div className="activity_right_tab">
                  <button>
                    <i>
                      <MdAccessTimeFilled />
                    </i>
                    <time>20.00</time>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="Activity_test_wrapper">
            <div className="Activity_close_icon">
              <Link to="/practicetest">
                {" "}
                <img src="\PrimeStudentImages\Close_Icon.svg" alt="" />
              </Link>
            </div>
            {hide ? (
              <div className="Activate_column_data">
                <div className="Activity_Row">
                  <div className="col-md-12 d-flex">
                    <div className="col-md-6 ">
                      <div className="Activity_test_Data d-flex">
                        <div className="col-md-1">
                          <div className="Active_Circle">
                            <span>a</span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="Active_Image">
                            <img src="\PrimeStudentImages\Balloon.svg" alt="" />
                            <img src="\PrimeStudentImages\Balloon.svg" alt="" />
                          </div>
                          <div className="Active_Caluclations">
                            <h6>
                              <span>3</span>
                              <span>+</span>
                              <span>2</span>
                              <span>=</span>
                              <input type="number" />
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="Active_marks">
                            <span>2 marks</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="Activity_test_Data d-flex">
                        <div className="col-md-1">
                          <div className="Active_Circle">
                            <span>a</span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="Active_Image">
                            <img src="\PrimeStudentImages\BlueSun.svg" alt="" />
                            <img src="\PrimeStudentImages\BlueSun.svg" alt="" />
                          </div>
                          <div className=" d-flex">
                            <div className="Active_Image">
                              <img
                                src="\PrimeStudentImages\BlueSun.svg"
                                alt=""
                              />
                            </div>
                            <div className="Active_Image_space">
                              <img
                                src="\PrimeStudentImages\SingleSun.svg"
                                alt=""
                              />
                              <img
                                src="\PrimeStudentImages\SingleSun.svg"
                                alt=""
                              />
                              <img
                                src="\PrimeStudentImages\SingleSun.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="Active_Caluclations">
                            <h6>
                              <span>10</span>
                              <span>+</span>
                              <span>9</span>
                              <span>=</span>
                              <input type="number" />
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="Active_marks">
                            <span>2 marks</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Activity_Row">
                  <div className="col-md-12 d-flex">
                    <div className="col-md-6 ">
                      <div className="Activity_test_Data d-flex">
                        <div className="col-md-1">
                          <div className="Active_Circle">
                            <span>a</span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="Active_Image">
                            <img
                              src="\PrimeStudentImages\Choclates.svg"
                              alt=""
                            />
                            <img
                              src="\PrimeStudentImages\Choclates.svg"
                              alt=""
                            />
                          </div>
                          <div className="Active_Caluclations">
                            <h6>
                              <span>4</span>
                              <span>+</span>
                              <span>4</span>
                              <span>=</span>
                              <input type="number" />
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="Active_marks">
                            <span>2 marks</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="Activity_test_Data d-flex">
                        <div className="col-md-1">
                          <div className="Active_Circle">
                            <span>a</span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="Active_Image">
                            <img
                              src="\PrimeStudentImages\HeartsymbolGroup.svg"
                              alt=""
                            />
                            <img
                              src="\PrimeStudentImages\HeartsymbolGroup.svg"
                              alt=""
                            />
                          </div>
                          {/* <div className="Active_Image">
                       <img src="\PrimeStudentImages\BlueSun.svg" alt="" />
                       <img src="\PrimeStudentImages\BlueSun.svg" alt="" />
                     </div> */}
                          <div className="Active_Caluclations">
                            <h6>
                              <span>8</span>
                              <span>+</span>
                              <span>8</span>
                              <span>=</span>
                              <input type="number" />
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="Active_marks">
                            <span>2 marks</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Activity_Row">
                  <div className="col-md-12 d-flex">
                    <div className="col-md-6 ">
                      <div className="Activity_test_Data d-flex">
                        <div className="col-md-1">
                          <div className="Active_Circle">
                            <span>a</span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="Active_Image">
                            <img
                              src="\PrimeStudentImages\HockystickGroup.svg"
                              alt=""
                            />
                            <img
                              src="\PrimeStudentImages\HockystickGroup.svg"
                              alt=""
                            />
                          </div>
                          <div className="Active_Caluclations">
                            <h6>
                              <span>5</span>
                              <span>+</span>
                              <span>5</span>
                              <span>=</span>
                              <input type="number" />
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="Active_marks">
                            <span>2 marks</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="Activity_test_Data d-flex">
                        <div className="col-md-1">
                          <div className="Active_Circle">
                            <span>a</span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="Active_Image">
                            <img
                              src="\PrimeStudentImages\MoonGroup.svg"
                              alt=""
                            />
                            <img
                              src="\PrimeStudentImages\MoonGroup.svg"
                              alt=""
                            />
                          </div>
                          {/* <div className="Active_Image">
                       <img src="\PrimeStudentImages\MoonGroup.svg" alt="" />
                       <img src="\PrimeStudentImages\MoonGroup.svg" alt="" />
                     </div> */}
                          <div className="Active_Caluclations">
                            <h6>
                              <span>10</span>
                              <span>+</span>
                              <span>10</span>
                              <span>=</span>
                              <input type="number" />
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="Active_marks">
                            <span>2 marks</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="Activate_column_data">
                <div className="Activity_Row">
                  <div className="col-md-12 d-flex">
                    <div className="col-md-6 ">
                      <div className="Activity_test_Data d-flex">
                        <div className="col-md-1">
                          <div className="Active_Circle">
                            <span>a</span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="Active_Image">
                            <img src="\PrimeStudentImages\Balloon.svg" alt="" />
                            <img src="\PrimeStudentImages\Balloon.svg" alt="" />
                          </div>
                          <div className="Active_Caluclations">
                            <h6>
                              <span>3</span>
                              <span>+</span>
                              <span>2</span>
                              <span>=</span>
                              <input type="number" />
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="Active_marks">
                            <span>2 marks</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="Activity_test_Data d-flex">
                        <div className="col-md-1">
                          <div className="Active_Circle">
                            <span>a</span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="Active_Image">
                            <img src="\PrimeStudentImages\BlueSun.svg" alt="" />
                            <img src="\PrimeStudentImages\BlueSun.svg" alt="" />
                          </div>
                          <div className=" d-flex">
                            <div className="Active_Image">
                              <img
                                src="\PrimeStudentImages\BlueSun.svg"
                                alt=""
                              />
                            </div>
                            <div className="Active_Image_space">
                              <img
                                src="\PrimeStudentImages\SingleSun.svg"
                                alt=""
                              />
                              <img
                                src="\PrimeStudentImages\SingleSun.svg"
                                alt=""
                              />
                              <img
                                src="\PrimeStudentImages\SingleSun.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="Active_Caluclations">
                            <h6>
                              <span>10</span>
                              <span>+</span>
                              <span>9</span>
                              <span>=</span>
                              <input type="number" />
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="Active_marks">
                            <span>2 marks</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Activity_Row">
                  <div className="col-md-12 d-flex">
                    <div className="col-md-6 ">
                      <div className="Activity_test_Data d-flex">
                        <div className="col-md-1">
                          <div className="Active_Circle">
                            <span>a</span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="Active_Image">
                            <img
                              src="\PrimeStudentImages\HockystickGroup.svg"
                              alt=""
                            />
                            <img
                              src="\PrimeStudentImages\HockystickGroup.svg"
                              alt=""
                            />
                          </div>
                          <div className="Active_Caluclations">
                            <h6>
                              <span>5</span>
                              <span>+</span>
                              <span>5</span>
                              <span>=</span>
                              <input type="number" />
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="Active_marks">
                            <span>2 marks</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="Activity_test_Data d-flex">
                        <div className="col-md-1">
                          <div className="Active_Circle">
                            <span>a</span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="Active_Image">
                            <img
                              src="\PrimeStudentImages\MoonGroup.svg"
                              alt=""
                            />
                            <img
                              src="\PrimeStudentImages\MoonGroup.svg"
                              alt=""
                            />
                          </div>
                          {/* <div className="Active_Image">
                        <img src="\PrimeStudentImages\MoonGroup.svg" alt="" />
                        <img src="\PrimeStudentImages\MoonGroup.svg" alt="" />
                      </div> */}
                          <div className="Active_Caluclations">
                            <h6>
                              <span>10</span>
                              <span>+</span>
                              <span>10</span>
                              <span>=</span>
                              <input type="number" />
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="Active_marks">
                            <span>2 marks</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Activity_Row">
                  <div className="col-md-12 d-flex">
                    <div className="col-md-6 ">
                      <div className="Activity_test_Data d-flex">
                        <div className="col-md-1">
                          <div className="Active_Circle">
                            <span>a</span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="Active_Image">
                            <img
                              src="\PrimeStudentImages\Choclates.svg"
                              alt=""
                            />
                            <img
                              src="\PrimeStudentImages\Choclates.svg"
                              alt=""
                            />
                          </div>
                          <div className="Active_Caluclations">
                            <h6>
                              <span>4</span>
                              <span>+</span>
                              <span>4</span>
                              <span>=</span>
                              <input type="number" />
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="Active_marks">
                            <span>2 marks</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="Activity_test_Data d-flex">
                        <div className="col-md-1">
                          <div className="Active_Circle">
                            <span>a</span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="Active_Image">
                            <img
                              src="\PrimeStudentImages\HeartsymbolGroup.svg"
                              alt=""
                            />
                            <img
                              src="\PrimeStudentImages\HeartsymbolGroup.svg"
                              alt=""
                            />
                          </div>
                          {/* <div className="Active_Image">
                        <img src="\PrimeStudentImages\BlueSun.svg" alt="" />
                        <img src="\PrimeStudentImages\BlueSun.svg" alt="" />
                      </div> */}
                          <div className="Active_Caluclations">
                            <h6>
                              <span>8</span>
                              <span>+</span>
                              <span>8</span>
                              <span>=</span>
                              <input type="number" />
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="Active_marks">
                            <span>2 marks</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Activity Buttons */}
            <div className="Activity_Next">
              {hide ? (
                <div>
                  {" "}
                  <button id="Activity_back" onClick={activateBack}>
                    Back
                  </button>
                  <button
                    id="Activity_submit"
                    onClick={() => openSubmitModal()}
                  >
                    Submit
                  </button>
                </div>
              ) : (
                <button id="Activity_Next" onClick={activateNext}>
                  Next
                </button>
              )}
            </div>
          </div>
          {/* Activity Bottom Page Data */}
          <div>
            {hide ? (
              <div className="d-flex">
                <div className="Activity_bottom">
                  <span>SqillUp Math: Addition upto 20 numbers</span>
                </div>
                <div className="Activity_Page_number">
                  <span>
                    <b>2</b>/2
                  </span>
                </div>
              </div>
            ) : (
              <div className="Activity_bottom d-flex">
                <div>
                  <span>SqillUp Math: Addition upto 20 numbers</span>
                </div>
                <div className="Activity_Page_number">
                  <span>
                    <b>1</b>/2
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        {submit ? (
          <div className="Submit_Form container-fluid ">
            <div className="Submit_Form_Border">
              <h4>Are You Sure? You want to submit Your Lesson activity?</h4>
              <div className="Submit_Form_buttons d-flex">
                <button id="submit_back" onClick={submitBack}>
                  Back
                </button>
                <Link to="/submit">
                  {" "}
                  <button id="submit_yes"onClick={playAudio}>Yes</button>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Activity;
