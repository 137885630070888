export const baseURL = "https://api.smartht.co.uk/api/";

export const base = "https://api.smartht.co.uk";

//Logi Page

export const login = "ps/login";

//Student Profile Information

export const profile = "ps/viewProfile";

//Greetings API

export const greetings = "ps/viewGreeting";
//View User
export const viewUser = "ps/viewUser";
//Notices API
export const notice = "ps/viewNotice";
// View Score ProgressBar Card API
//View Score API Dashboard
export const score = "ps/viewScore";
//View Score Lesson API
export const scoreLesson = "ps/ViewLessonScore";
//List Of Subject API
export const subject = "ps/viewSubject";
//List of Exam Type API
export const examType = "ps/viewExamType";
//List of Chapter API
export const chapter = "ps/viewChapter";
// Avathar Changes API
export const viewAvathar ="ps/viewAvatar";

export const viewAdminAvathar ="ps/viewAdminAvatar";

export const createAvathar ="ps/createAvatar";

export const updateAvathar ="ps/updateAvatar";

export const selectAvathar ="ps/selectAvatar";

export const deleteAvathar ="ps/deleteAvatar";
//Courses API
export const lesson ="ps/viewLesson";

export const selftest ="ps/viewSelfTest";

export const viewOngoing ="ps/viewOngoingLesson";

export const storeOngoing ="ps/StoreOngoingLesson";

export const updateOngoing ="UpdateStudentOngoingLesson";
//Notifications API
export const notification = "ps/viewNotice";
