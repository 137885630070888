import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Courses.css";
import { AiOutlineHeart } from "@react-icons/all-files/ai/AiOutlineHeart";
import { Link, useNavigate,useParams } from "react-router-dom";
import Moment from "react-moment";
import { studentViewLesson, listOfChapter,listOfSubjects } from "../Services";
const calendarStrings = {
  lastDay: "[Yesterday at] LT",
  sameDay: "[Today at] LT",
  nextDay: "[Tomorrow at] LT",
  lastWeek: "[last] dddd [at] LT",
  nextWeek: "dddd [at] LT",
  sameElse: "L",
};
function Lessons() {
  const listCourses = [];
  const [lessons, setLessons] = useState("alllessons");
  const [lessonData, setLessonData] = useState("");
  const [chapterData, setChapter] = useState("");
  const { id } = useParams();
  const { subject } = useParams();
  useEffect(() => {
    studentViewLesson().then((data) => {
      setLessonData(data);
    });
    listOfChapter("listOfChapter?id=" + id + subject).then((data) => {
      setChapter(data[0]);
  });
  }, []);

  const lessonHandler = (e) => {
    setLessons(e);
  };
  let navigate = useNavigate();
  const lessonDetailView = (e, data) => {
    navigate("/lessonview/" + data.id + "/" + data.chapter_id);
  };
  const lessonActivate = () => {
    const lesson = document.querySelectorAll(".lesson");
    function activeLink() {
      lesson.forEach((item) => item.classList.remove("active"));
      this.classList.add("active");
    }
    lesson.forEach((item) => item.addEventListener("click", activeLink));
  };
  
  return (
    <div className="Lesson_Wrapper">
      <div className="container-fluid">
        <div className="Lesson_top_bar d-flex">
          <div className="Lesson_top_left">
            {/* <h5 onClick={()=>handelAllLessons("1")}> */}
            <h5 >
              Maths &nbsp; <b>&gt;</b> &nbsp; &nbsp;
              <span>{chapterData.name}</span>
            </h5>
          </div>

          <div className="Lesson_top_right">
            <Link to="/courses/">
              {" "}
              <button>Back</button>
            </Link>
          </div>
        </div>
        <div className="Lesson_Types d-flex" onClick={lessonActivate}>
          <button
            onClick={() => lessonHandler("alllessons")}
            id="alllessons"
            className="lesson active"
          >
            <span>
              <img src="\PrimeStudentImages\Play-button_Icon.svg" alt="" />
            </span>
            &nbsp;All Lesson
          </button>
          <button
            onClick={() => lessonHandler("ongoing")}
            id="ongoing"
            className="lesson"
          >
            <span>
              <img src="\PrimeStudentImages\Gold_Circle_Icon.svg" alt="" />
            </span>
            &nbsp;Ongoing
          </button>
          <button
            onClick={() => lessonHandler("wishlisted")}
            id="wishlisted"
            className="lesson"
          >
            <span>
              <img src="\PrimeStudentImages\Heart_Symbol_Icon.svg" alt="" />
            </span>
            &nbsp;Wish-listed
          </button>
          <button
            onClick={() => lessonHandler("completed")}
            id="completed"
            className="lesson"
          >
            <span>
              <img src="\PrimeStudentImages\Tic_Icon.svg" alt="" />
            </span>
            &nbsp;Completed
          </button>
        </div>
        <div className="container-fluid">
          <div className="Lessons_tabs_data">
            {lessons === "alllessons" ? (
              <div className="Lessons_tab_cards">
                <div className="col-md-12 d-flex">
                  <div className="row">
                    {lessonData.length > 0 &&
                      lessonData.map((data, index) => (
                        <div className="col-md-4">
                          <div
                            className="card"
                            onClick={(e) => lessonDetailView(e, data)}
                          >
                            <div className="card-body">
                              <div className="card-title">
                                <div>
                                  <p>
                                    {data?.number}
                                    {data?.name}
                                  </p>
                                  <img
                                    src="\PrimeStudentImages\Number_Blocks_Icon.svg"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="card-footer1">
                                <div>
                                  <img
                                    src="\PrimeStudentImages\Video_Player_Icon.svg"
                                    alt=""
                                  />
                                  <time>
                                    <Moment calendar={calendarStrings}>
                                      {data?.created_at}
                                    </Moment>
                                  </time>
                                  <i>
                                    <AiOutlineHeart />
                                  </i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {lessons === "ongoing" ? (
              <div className="Lessons_tab_cards">
                <div className="col-md-12 d-flex">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title">
                          <div>
                            <p>1.1 Addition and subtraction</p>
                            <img
                              src="\PrimeStudentImages\Number_Blocks_Icon.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="card-footer1">
                          <div>
                            <img
                              src="\PrimeStudentImages\Video_Player_Icon.svg"
                              alt=""
                            />
                            <time>10 min</time>
                            <i>
                              <AiOutlineHeart />
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title">
                          <div>
                            <p>1.2 multiplication and division and etc</p>
                            <img
                              src="\PrimeStudentImages\Number_Blocks_Icon.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="card-footer1">
                          <div>
                            <img
                              src="\PrimeStudentImages\Video_Player_Icon.svg"
                              alt=""
                            />
                            <time>10 min</time>
                            <i>
                              <AiOutlineHeart />
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title">
                          <div>
                            <p>1.3 Place value within 10</p>
                            <img
                              src="\PrimeStudentImages\Number_Blocks_Icon.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="card-footer1">
                          <div>
                            <img
                              src="\PrimeStudentImages\Video_Player_Icon.svg"
                              alt=""
                            />
                            <time>10 min</time>
                            <i>
                              <AiOutlineHeart />
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {lessons === "wishlisted" ? (
              <div className="Lessons_tab_cards">
                <div className="col-md-12 d-flex">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title">
                          <div>
                            <p>1.1 Addition and subtraction</p>
                            <img
                              src="\PrimeStudentImages\Number_Blocks_Icon.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="card-footer1">
                          <div>
                            <img
                              src="\PrimeStudentImages\Video_Player_Icon.svg"
                              alt=""
                            />
                            <time>10 min</time>
                            <i>
                              <AiOutlineHeart />
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title">
                          <div>
                            <p>1.2 multiplication and division and etc</p>
                            <img
                              src="\PrimeStudentImages\Number_Blocks_Icon.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="card-footer1">
                          <div>
                            <img
                              src="\PrimeStudentImages\Video_Player_Icon.svg"
                              alt=""
                            />
                            <time>10 min</time>
                            <i>
                              <AiOutlineHeart />
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title">
                          <div>
                            <p>1.3 Place value within 10</p>
                            <img
                              src="\PrimeStudentImages\Number_Blocks_Icon.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="card-footer1">
                          <div>
                            <img
                              src="\PrimeStudentImages\Video_Player_Icon.svg"
                              alt=""
                            />
                            <time>10 min</time>
                            <i>
                              <AiOutlineHeart />
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {lessons === "completed" ? (
              <div className="Lessons_tab_cards">
                <div className="col-md-12 d-flex">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title">
                          <div>
                            <p>1.1 Addition and subtraction</p>
                            <img
                              src="\PrimeStudentImages\Number_Blocks_Icon.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="card-footer1">
                          <div>
                            <img
                              src="\PrimeStudentImages\Video_Player_Icon.svg"
                              alt=""
                            />
                            <time>10 min</time>
                            <i>
                              <AiOutlineHeart />
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title">
                          <div>
                            <p>1.2 multiplication and division and etc</p>
                            <img
                              src="\PrimeStudentImages\Number_Blocks_Icon.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="card-footer1">
                          <div>
                            <img
                              src="\PrimeStudentImages\Video_Player_Icon.svg"
                              alt=""
                            />
                            <time>10 min</time>
                            <i>
                              <AiOutlineHeart />
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title">
                          <div>
                            <p>1.3 Place value within 10</p>
                            <img
                              src="\PrimeStudentImages\Number_Blocks_Icon.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="card-footer1">
                          <div>
                            <img
                              src="\PrimeStudentImages\Video_Player_Icon.svg"
                              alt=""
                            />
                            <time>10 min</time>
                            <i>
                              <AiOutlineHeart />
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Lessons;
