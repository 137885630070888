import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Curriculum.css";
import { BiDownload } from "react-icons/bi";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
function Curriculum() {
  return (
    <>
      <div className="MyPracticeTest_wrapper">
        <div className="container-fluid">
          <div className="col-md-12 d-flex">
            <div className="col-md-6">
              <div className="MyPracticeTest_Dropdown">
                <select
                  className="form-control dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <option id="math">Math</option>
                  <option id="science">Science</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="curriculum_right_tab">
                <>
                  <span>Download KS1 syllabus for AQA</span>
                  <button>
                    Download syllabus
                    <i>
                      <BiDownload />
                    </i>
                  </button>
                </>
                <img src="\PrimeStudentImages\AQA.png" alt="AQA" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="curriculum_Wrapper">
        <div className="container-fluid">
          <div className="col-md-12 d-flex">
            <div className="col-md-4">
              <div className="curriculum_unit_left">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Unit name</th>
                    </tr>
                  </thead>
                </table>
                <div className="curriculum_unit_left1">
                  <p id="active">Unit 1: Pure mathmetics1</p>
                  <p>Unit 2: Pure mathmetics2</p>
                  <p>Unit 3: Pure mathmetics3</p>
                  <p>Unit 4: Pure mathmetics4</p>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="curriculum_unit_right">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Chapter no</th>
                      <th>Chapter name</th>
                    </tr>
                  </thead>
                </table>
                <div className="curriculum_unit_right1">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          Algebra and function{" "}
                          <Link to="/curriculumguidline">
                            {" "}
                            <span>
                              <AiOutlineRight />
                            </span>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          Sequence and series{" "}
                          <span>
                            <AiOutlineRight />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>
                          Co-ordinate geometry in the (X,Y) plan{" "}
                          <span>
                            <AiOutlineRight />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>
                          Trigonometry{" "}
                          <span>
                            <AiOutlineRight />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Curriculum;
