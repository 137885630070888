import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Courses.css";
import { useNavigate } from "react-router-dom";
import { listOfSubjects, listOfChapter, studentViewLesson } from "../Services";
const initialvalues = {
  subject_id: "",
  chapter_id: "",
  lesson_id: "",
  lesson_type_id: "",
};
function Courses() {
  const [requestData, setRequestData] = useState(initialvalues);

  // const [selectsubject, setSelectSubject] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [chapter, setChapter] = useState([]);

  // const [selectchapeter, setSelectChapter] = useState("");
  // const [chaptertype, setChaptertype] = useState([]);
  // const [chaptertypeShow, setChaptertypeshow] = useState([]);
  // const [subInfo, setSubInfo] = useState({ subject: "", chapter: "" });
  useEffect(() => {
    listOfSubjects().then((data) => {
      setSubjects(data);
    });
    listOfChapter().then((data) => {
      setChapter(data);
    });
  }, []);
  const chapterChange = (e) => {
    setRequestData({ ...requestData, [e.target.name]: [e.target.value] });
    // setSelectChapter("");
    // setSelectSubject(e.target.value);
    // setSelectChapter("");
    // let value = subjects.filter((s) => s.value == e.target.value);
    // let val = subInfo;
    // val.subject = value[0].label;
    // val.chapter = "";
    // setSubInfo(val);
    // if (e.target.value) {
    //   let fil = chaptertype.filter((c) => c.sub == e.target.value);
    //   setChaptertypeshow(fil);
    // } else {
    //   setChaptertypeshow(chaptertype);
    // }
  };

  let navigate = useNavigate();
  const lessonHandler = (e, chapter) => {
    navigate("/lessons/" + chapter.id + "/" + chapter.subject_id);
    // navigate("/lessons");
  };
  return (
    <>
      <div className="Courses_Wrapper">
        <div className="container-fluid">
          <div className="Courses_Dropdown">
            <select
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
              onChange={chapterChange}
            >
              {subjects.length > 0 &&
                subjects.map((subject, index) => (
                  <option id="science" value={subject?.id}>
                    {subject?.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="Courses_Cards">
            {chapter.length > 0 &&
              chapter.map((chapter, index) => (
                <div className="col-md-12 d-flex">
                  <div
                    className="col-md-4 d-flex"
                    onClick={(e) => lessonHandler(e, chapter)}
                  >
                    <img src="\PrimeStudentImages\Chapter-1.svg" alt="C1" />
                    {/* <img src={chapter?.image} alt="C1" /> */}
                    <div>
                      <h5>
                        Chapter 1 <b>{chapter?.name}</b>
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Courses;
