import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Courses.css";
import { MdAccessTimeFilled, MdVolumeUp } from "react-icons/md";
import { Link } from "react-router-dom";
import Sounds from "../LottieJsonFiles/Sounds.mp3";
import { useLocation } from "react-router-dom";
import { studentSelftest } from "../Services";
function Selftest() {
  const [questions, setQuestions] = useState([]);
  const [resultRes, setResultRes] = useState([]);
  const [selectIndex, setSelectIndex] = useState(0);
  const [disable, setDisable] = useState(false);
  const [viewResult, setViewResult] = useState(false);
  const [resIndex, setResIndex] = useState(0);
  const location = useLocation();
  useEffect(() => {
    studentSelftest(location.state, "ps/viewSelfTest").then((data) => {
      setQuestions(data);
    });
  }, []);
  const playAudio = () => {
    new Audio(Sounds).play();
  };
  const handleSubmit = (answer, index) => {
    setResIndex(resIndex + 1);
    setDisable(true);
    let data = questions[0].questions_data[selectIndex];
    let resHistory = resultRes;
    if (data?.correct_answers[0]?.answer === answer) {
      let obj = {
        user_res: answer,
        result: "correct",
        solutions: data.solution,
      };
      resHistory.push(obj);
      setResultRes(resHistory);
    } else {
      let obj = {
        user_res: answer,
        result: "wrong",
        solutions: data.solution,
      };
      resHistory.push(obj);
      setResultRes(resHistory);
    }

    setViewResult(false);
  };
  const alph = ["A", "B", "C", "D"];
  
  return (
    <>
      <body className="postid-36782 ">
        <div className="Selftest_Wrapper">
          <div className="container">
            <div className="col-md-12 d-flex">
              <div className="col-md-2">
                <div className="Selftest_Time">
                  <button>
                    <MdAccessTimeFilled />
                    &nbsp;
                    <time>01.00</time>
                  </button>
                </div>
              </div>
              <div className="col-md-8">
                <div className="pagination">
                  {questions[0]?.questions.map((data, index) => (
                    <span
                      className={`blocks active ${
                        resultRes.length && resultRes[index]
                          ? resultRes[index]?.result === "correct"
                            ? "bg_success correct_answers"
                            : "bg_fail wrong_answers"
                          : ""
                      }`}
                    >
                      {index + 1}
                    </span>
                  ))}
                </div>
              </div>
              <div className="col-md-2">
                <div className="Selftest_Back_button">
                  <Link to="/lessonview">
                    {" "}
                    <button>Close</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="Selftest_body">
              <div className="Selftest_Question">
                {/* <p>
                  Q1.
                  <i>
                    <MdVolumeUp />
                  </i>{" "}
                  Ben ate half a Pizza
                </p>
                <h5>Which fraction shows the amount he ate</h5>
                <h6>Select the correct box.</h6> */}
                {questions.length > 0 && (
                  <h5>
                    <div
                      id="question_description"
                      dangerouslySetInnerHTML={{
                        __html: `
                  ${questions[0]?.questions_data[selectIndex]?.question_number} : ${questions[0]?.questions_data[selectIndex].question} `,
                      }}
                    ></div>
                  </h5>
                )}
                <img src="\PrimeStudentImages\Half Pizza.svg" alt="C1" />
              </div>
              <div className="Selftest_Options">
                {/* <button>A. 1/2</button> <button>B. 3/2</button> */}
                <ul>
              {questions.length > 0 &&
                questions[0]?.questions_data[selectIndex]?.answers.map(
                  (option, index) => (
                    <li
                      className={
                        disable
                          ? `curser_event ${
                              resultRes[selectIndex].user_res === option.answer
                                ? resultRes[selectIndex].result === "correct"
                                  ? "bg_success"
                                  : "bg_fail"
                                : ""
                            }`
                          : "cursor_pointer"
                      }
                      onClick={() => handleSubmit(option.answer, index)}
                    >
                      {alph[index]} : {option.answer}
                    </li>
                  )
                )}
            </ul>
              </div>
              {/* <div className="Selftest_Options">
                <button>C. 3/6</button> <button>D. 2/2</button>
              </div> */}
              <div className="Close_Button_Selftest">
                <Link to="/selftest2">
                  <button onClick={playAudio}>Next</button>
                </Link>
              </div>
            </div>
            <div className="Selftest_bottom_Chapter">
              <span>Chapter 1: 1.1 addition and subtraction</span>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}

export default Selftest;
