import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/MyPracticeTest.css";
import { Link } from "react-router-dom";
function MyPracticeTest() {
  const [activitytab, setActivityTab] = useState("assigned");
  
  const mypracticeActive = () => {
    const list = document.querySelectorAll(".test");
    function activeLink() {
      list.forEach((item) => item.classList.remove("active"));
      this.classList.add("active");
    }
    list.forEach((item) => item.addEventListener("click", activeLink));
  };
  const lessonActivity = (e) => {
    setActivityTab(e);
  };
  return (
    <>
      <div className="MyPracticeTest_wrapper">
        <div className="container-fluid">
          <div className="MyPracticeTest_Dropdown">
            <select
              className="form-control dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <option id="math">Math</option>
              <option id="science">Science</option>
            </select>
          </div>
        </div>
      </div>
      <div className="MyPracticeTest_tabs">
        <div className="col-md-12 d-flex">
          <div className="col-md-8">
            <div
              className="MyPracticeTest_tab_buttons"
              onClick={mypracticeActive}
            >
              <button
                className="test active"
                onClick={() => lessonActivity("assigned")}
              >
                Assigned
              </button>
              <button
                className="test"
                onClick={() => lessonActivity("completed")}
              >
                Completed
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="MyPracticeTest_Chapter_Dropdown d-md-flex justify-content-md-end">
              {" "}
              <select className="dropdown-toggle" data-bs-toggle="dropdown">
                <option>Select Chapter</option>
                <option>Chapter 1</option>
                <option>Chapter 2</option>
                <option>Chapter 3</option>
                <option>Chapter 4</option>
                <option>Chapter 5</option>
              </select>
            </div>
          </div>
        </div>
        <div className="MyPracticeTest_activity">
          {activitytab === "assigned" ? (
            <div className="MyPracticeTest_table">
              <div className="col-md-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Lesson Activity</th>
                      <th>Chapter</th>
                      <th>Test to do</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1.1 Adding upto 20 numbers</td>
                      <td>
                        {" "}
                        <p>Addition</p>
                      </td>
                      <td>14/12/2022</td>
                      <td>
                        <Link to="/activity">
                          <button>Start</button>
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>1.2 Find the missing number</td>
                      <td>
                        <p>Addition</p>
                      </td>
                      <td>14/12/2022</td>
                      <td>
                        <Link to="/activity">
                          <button>Start</button>
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>2.1 Subtraction upto 10 numbers</td>
                      <td>
                        <p>Subtraction</p>
                      </td>
                      <td>14/12/2022</td>
                      <td>
                        <Link to="/activity">
                          <button>Start</button>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
          {activitytab === "completed" ? (
            <div className="MyPracticeTest_table">
              <div className="col-md-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Lesson Activity</th>
                      <th>Chapter</th>
                      <th>Attempt date</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1.1 Adding upto 20 numbers</td>
                      <td>
                        {" "}
                        <p>Addition</p>
                      </td>
                      <td>14/12/2022</td>
                      <td>
                        <h5>18/20</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>1.2 Find the missing number</td>
                      <td>
                        <p>Addition</p>
                      </td>
                      <td>14/12/2022</td>
                      <td>
                        <h5>18/20</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>2.1 Subtraction upto 10 numbers</td>
                      <td>
                        <p>Subtraction</p>
                      </td>
                      <td>14/12/2022</td>
                      <td>
                        <h5>18/20</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default MyPracticeTest;
