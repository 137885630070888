import React from 'react'

function Pastpaper() {
  return (
    <>
      <div className="content">
        <h1>Pastpaper-Primary Student</h1>
      </div>
    </>
  )
}

export default Pastpaper