import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/SigninPage.css";
import { AiOutlineCheck } from "@react-icons/all-files/ai/AiOutlineCheck";
import { AiFillEye } from "@react-icons/all-files/ai/AiFillEye";
import { AiFillEyeInvisible } from "@react-icons/all-files/ai/AiFillEyeInvisible";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineInfoCircle } from "@react-icons/all-files/ai/AiOutlineInfoCircle";
import { primaryStudentLogin } from "../Services";

const SigninPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, seterrorMessage] = useState("");
  const [valid, setValid] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const history = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("loginUser")) {
      history.push("/home");
    }
  }, []);

  const validate = (values) => {
    const errors = {};

    if (!values.user_name) {
      errors.username = "Username is required!";
    }

    if (!values.password) {
      errors.password = "Password is required.";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    return errors;
  };

  const handelFormSubmit = () => {
    // console.warn(username, password);
    const initialValues = {
      user_name: username,
      password: password,
    };
    setFormErrors(validate(initialValues));
    primaryStudentLogin(initialValues)
      .then((data) => {
        setValid(false);
        sessionStorage.setItem("primaryStudentToken", data.token);
        sessionStorage.setItem("loginUser", data.user?.id);
        history("/home");
      })
      .catch((error) => {
        seterrorMessage(error?.response?.data?.message);
        setValid(true);
      });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <>
      <div className="signin_wrapper">
        <div className="container-fluid">
          <div className="flex-container">
            <div className="row main-banner">
              <div className="col-md-6 top-p">
                <div className="login-banner">
                  <img src="\PrimeStudentImages\Login_Icon@2x.png" alt="" />
                </div>
                <div className="Logo">
                  <img src="\PrimeStudentImages\Logo_icon@02.svg" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="right-content">
                  <div className="signin">
                    <h1>Welcome back</h1>
                  </div>
                  <div className="p2">
                    <p>Sign in to your account</p>
                  </div>
                  <div className="p3">
                    <p>Enter your email address and password below</p>
                  </div>
                </div>
                <div className="row">
                  <div className="p4">
                    <label className="label_signin">Sign in as</label>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="parent"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Parent
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        value="student"
                        checked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        Student
                      </label>
                    </div>
                  </div>
                </div>
                <form className="student-form" autoComplete="off">
                  {formErrors.username != undefined ? (
                    <span className="error-msg">
                      <span className="pr-2">
                        <i>
                          <AiOutlineInfoCircle />
                        </i>
                      </span>
                      &nbsp;
                      {/* Invalid Login details */}
                      {formErrors.username}
                    </span>
                  ) : (
                    <pre></pre>
                  )}
                  <div className="form-group">
                    <label>Enter username</label>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      placeholder="Username"
                      className="form-control invalid-error"
                      onChange={(e) => setUsername(e.target.value)}
                    ></input>
                    <div className="eye-visible">
                      <i>
                        {" "}
                        <AiOutlineCheck />
                      </i>
                    </div>
                  </div>
                  {formErrors.password != undefined ? (
                    <span className="error-msg">
                      <span className="pr-2">
                        <i>
                          <AiOutlineInfoCircle />
                        </i>
                      </span>
                      &nbsp;
                      {/* Invalid Login details */}
                      {formErrors.password}
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="password"
                      className="form-control invalid-error"
                      onChange={(e) => setPassword(e.target.value)}
                    ></input>
                    <div className="eye-visible" onClick={togglePassword}>
                      {passwordType === "password" ? (
                        <i>
                          {" "}
                          <AiFillEye />
                        </i>
                      ) : (
                        <i>
                          {" "}
                          <AiFillEyeInvisible />
                        </i>
                      )}
                    </div>
                  </div>
                  {/* {valid ? (
                    <span className="error-msg">{errorMessage}</span>
                  ) : (
                    ""
                  )} */}
                  <div className="form-group">
                    <input
                      type="checkbox"
                      formcontrolname="checkbox"
                      id="exampleCheck1"
                      className="form-check-input"
                      checked
                    />
                    <label htmlFor="exampleCheck1" className="form-check-label">
                      Remember Me
                    </label>
                    <Link to="#" className="forgot">
                      Forgot Password?
                    </Link>
                  </div>
                </form>
                <div className="Signin-Button">
                  <button type="submit" onClick={handelFormSubmit}>
                    SIGN IN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SigninPage;
