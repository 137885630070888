import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Homepage.css";
import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { studentProfile } from "../Services";
function Topbar() {
  const [popup, setPopUP] = useState(false);
  const [profile, setProfile] = useState("");

  useEffect(() => {
    studentProfile().then((data) => {
      setProfile(data);
      // console.log(data);
    });
  }, []);

  const notificationPopUP = () => {
    setPopUP(true);
  };
  const closenotification = () => {
    setPopUP(false);
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid ">
          <div className="col-lg-12 col-md-12 col-sm-8 col-xs-6 d-flex">
            <div className="col-md-6"></div>
            <div className="d-flex Right-Side-align">
              <div className="col-md-2">
                <div className="d-md-flex justify-md-content-end">
                  <div
                    className="Notifications"
                    onClick={() => notificationPopUP()}
                  >
                    <Link to="#">
                      <img
                        src="\PrimeStudentImages\Notificaion_Icon.svg"
                        alt=""
                      />
                    </Link>
                  </div>
                  {/* &nbsp; */}
                  <div className="Badges">
                    <Link to="/badges">
                      <img src="\PrimeStudentImages\BadgeTop.svg" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-md-flex justify-md-content-end">
                <div className="profile-dropdown ">
                  <ul className="navbar-nav ">
                    <li className="nav-item Profile">
                      {/* <img src="\PrimeStudentImages\User_Profile.png" alt={profile.full_name} /> */}
                      <img src={profile.image} alt={profile.full_name} />
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        to="#"
                        className="nav-link myprofile_top_data"
                        data-toggle="dropdown"
                        title="Login User"
                      >
                        <p className="dropdown-toggle">
                          Hello, <b>{profile.full_name}</b>
                        </p>
                        <span>Student</span>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right top-user">
                        <div className="profile_dropdown_image d-flex">
                          {/* <img
                            src="\PrimeStudentImages\User_Profile@2x.png"
                            alt=""
                          /> */}
                          <img
                            id="profile_Imag"
                            src={profile.image}
                            alt={profile.full_name}
                          />
                          <Link to="#">
                            {" "}
                            <div id="close_icon">
                              <img
                                src="\PrimeStudentImages\Close_Icon.svg"
                                alt=""
                              />
                            </div>
                          </Link>
                        </div>
                        <div className="Profile_View">
                          <p>{profile.full_name}</p>
                          <Link to="/myprofile">
                            <button>
                              <i>
                                <AiFillEye />
                              </i>{" "}
                              View Profile
                            </button>
                          </Link>
                        </div>
                        <div className="SignOut_Page">
                          <Link to="/signout">
                            {" "}
                            <button>Sign out</button>
                          </Link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {popup ? (
            <div className="Notification_POP_up">
              <div className="col-md-12">
                <div className="notification_design d-flex">
                  <div className="col-md-2">
                    <div className="notification_left">
                      <img
                        src="\PrimeStudentImages\ConicalFlask_Icon.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="notification_right d-flex">
                      <p>New Science Video has Uploaded by Sqillup</p>
                      <img
                        src="\PrimeStudentImages\Close_Icon.svg"
                        alt=""
                        onClick={() => closenotification()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 active">
                <div className="notification_design d-flex">
                  <div className="col-md-2">
                    <div className="notification_left">
                      <img
                        src="\PrimeStudentImages\ConicalFlask_Icon.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="notification_right d-flex">
                      <p>There is a New Math Practice test for you.</p>
                    </div>
                  </div>
                </div>
              </div>
              <Link to="/notifications" onClick={() => closenotification()}>
                <button>View all</button>
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
      </nav>
    </>
  );
}

export default Topbar;
