import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Courses.css";
import { FaPlusCircle } from "@react-icons/all-files/fa/FaPlusCircle";
import { Link } from "react-router-dom";
import { AiOutlineArrowUp } from "@react-icons/all-files/ai/AiOutlineArrowUp";
function QandA() {
  const [postquestion, setPostQuestion] = useState(false);
  const [post, setPost] = useState(false);

  const postQuestionHandel = () => {
    setPostQuestion(true);
  };
  const closePostQuestion = () => {
    setPostQuestion(false);
  };
  const qandaquestionPost = () => {
    setPost(true);
  };
  return (
    <div className="QandA_wrapper">
      <div className="container_fluid">
        <div className="col-md-12 d-flex">
          <div className="col-md-6">
            <div className="LeftSide_QandA">
              <h5>Chapter 3:3.1 Introduction</h5>
            </div>
          </div>
          <div className="col-md-6">
            <div className="RightSide_QandA d-flex">
              <button id="PostQuestion" onClick={postQuestionHandel}>
                <i>
                  <FaPlusCircle />
                </i>
                Post Question
              </button>
              <Link to="/lessonview">
                {" "}
                <button id="QandA_back">Back</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="QandA_Comment">
          <div classname="">
            <p className="QandA-row">
              All Questions in this Course <span>(10)</span>
            </p>
          </div>
          <div className="row">
            <div className="QandA-row">
              <div className="col-md-12 d-flex ">
                <div className="colmd-8 d-block ">
                  <div className="QandA">
                    <div className="QandA__item">
                      <div className="QandA__column QandA__column--left">
                        <div className="QandA-Image">
                          <img
                            src="\PrimeStudentImages\User-circle.png"
                            alt=""
                          />
                        </div>
                        <div className="QandA__info">
                          <span className="QandA__question">
                            Lorem ipsum dolor sit amet, consetetur sadips elitr,
                            sed diam?
                          </span>

                          <span className="QandA__answer">
                            Lorem ipsum dolor sit amet, consetetur sadips elitr,
                            sed diam nonumy eirmod tempor invidunt ut labore et
                            dolore magna aliquyam erat, sed diam voluptua. At
                            vero eos et accusam et justo. duo dolores et ea
                            rebum. Stet clita kasd gubergren, no sea takimata
                            sanctus est Lorem ipsum dolor sit amet.
                          </span>
                          <div className="QandA-uls_lis d-flex">
                            <p>Student</p>
                            <p>
                              <b>&#8729;</b>
                              <span>2 days ago</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 d-md-flex justify-content-md-end pt-3">
                  <div className="QandA_tag1">
                    <p onClick={() => qandaquestionPost()}>
                      {" "}
                      2 <img src="\PrimeStudentImages\Comments.png" alt="" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {post ? (
            <div className="QandA_HideandShow">
              <div className="col-md-12">
                <div classname="col-md-6">
                  <div className="conents_left  col-md-6">
                    <div className="QandA-Image_left">
                      <img src="\PrimeStudentImages\User-circle.png" alt="" />
                    </div>
                    <div className="QandA__info">
                      <span className="QandA__answer">
                        Lorem ipsum dolor sit amet, consetetur sadips elitr, sed
                        diam nonumy eirmod tempor invidunt ut labore et dolore
                        magna aliquyam erat, sed diam voluptua. At vero eos et
                        accusam et justo. duo dolores et ea rebum. Stet clita
                        kasd gubergren, no sea takimata sanctus est Lorem ipsum
                        dolor sit amet.
                      </span>
                      <div className="QandA-Bottom_Data d-flex">
                        <p>Student</p>
                        <p>
                          <b>&#8729;</b>
                          <span>2 days ago</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div classname="col-md-6">
                  <div className="conents_right">
                    <div className="QandA-Image_right">
                      <img src="\PrimeStudentImages\User-circle.png" alt="" />
                    </div>
                    <div className="QandA__info">
                      <span className="QandA__answer">
                        Lorem ipsum dolor sit amet, consetetur sadips elitr, sed
                        diam nonumy eirmod tempor invidunt ut labore et dolore
                        magna aliquyam erat, sed diam voluptua. At vero eos et
                        accusam et justo. duo dolores et ea rebum. Stet clita
                        kasd gubergren, no sea takimata sanctus est Lorem ipsum
                        dolor sit amet.
                      </span>
                      <div className="QandA-Bottom_Data d-flex">
                        <p>SqillUP Team</p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                          <b>&#8729;</b>
                          <span>2 days ago</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="QandA-textarea d-flex">
                <span>
                  <img src="\PrimeStudentImages\User-circle.png" alt="" />
                </span>
                <div class="Text_area">
                  <input class="input__inner" type="text" name="my_input" />
                </div>
                <span>
                  <img src="\PrimeStudentImages\paper-plane.png" alt="" />
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="QandA-row">
              <div className="col-md-12 d-flex ">
                <div className="colmd-8 d-block ">
                  <div className="QandA">
                    <div className="QandA__item">
                      <div className="QandA__column QandA__column--left">
                        <div className="QandA-Image">
                          <img
                            src="\PrimeStudentImages\User-circle.png"
                            alt=""
                          />
                        </div>
                        <div className="QandA__info">
                          <span className="QandA__question">
                            Lorem ipsum dolor sit amet, consetetur sadips elitr,
                            sed diam?
                          </span>

                          <span className="QandA__answer">
                            Lorem ipsum dolor sit amet, consetetur sadips elitr,
                            sed diam nonumy eirmod tempor invidunt ut labore et
                            dolore magna aliquyam erat, sed diam voluptua. At
                            vero eos et accusam et justo. duo dolores et ea
                            rebum. Stet clita kasd gubergren, no sea takimata
                            sanctus est Lorem ipsum dolor sit amet.
                          </span>
                          <div className="QandA-uls_lis d-flex">
                            <p>Student</p>
                            <p>
                              <b>&#8729;</b>
                              <span>2 days ago</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 d-md-flex justify-content-md-end pt-3">
                  <div className="QandA_tag1">
                    <p onClick={() => qandaquestionPost()}>
                      {" "}
                      2 <img src="\PrimeStudentImages\Comments.png" alt="" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="QandA-row">
              <div className="col-md-12 d-flex ">
                <div className="colmd-8 d-block ">
                  <div className="QandA">
                    <div className="QandA__item">
                      <div className="QandA__column QandA__column--left">
                        <div className="QandA-Image">
                          <img
                            src="\PrimeStudentImages\User-circle.png"
                            alt=""
                          />
                        </div>
                        <div className="QandA__info">
                          <span className="QandA__question">
                            Lorem ipsum dolor sit amet, consetetur sadips elitr,
                            sed diam?
                          </span>

                          <span className="QandA__answer">
                            Lorem ipsum dolor sit amet, consetetur sadips elitr,
                            sed diam nonumy eirmod tempor invidunt ut labore et
                            dolore magna aliquyam erat, sed diam voluptua. At
                            vero eos et accusam et justo. duo dolores et ea
                            rebum. Stet clita kasd gubergren, no sea takimata
                            sanctus est Lorem ipsum dolor sit amet.
                          </span>
                          <div className="QandA-uls_lis d-flex">
                            <p>Student</p>
                            <p>
                              <b>&#8729;</b>
                              <span>2 days ago</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 d-md-flex justify-content-md-end pt-3">
                  <div className="QandA_tag1">
                    <p onClick={() => qandaquestionPost()}>
                      {" "}
                      2 <img src="\PrimeStudentImages\Comments.png" alt="" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {postquestion ? (
          <div className="postFile_container container-fluid ">
            <div className="PostFile_Data d-flex">
              <p>Post Question</p>
              <div onClick={closePostQuestion}>
                <img src="\PrimeStudentImages\Close_Icon.svg" alt="" />
              </div>
            </div>
            <div className="PostFile_Question">
              <textarea
                rows="10"
                cols="80"
                placeholder="Type your Question"
              ></textarea>
            </div>
            <div className="PostFile_Button">
              <button>Submit</button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default QandA;
