import axios from "axios";
import {
  baseURL,
  login,
  profile,
  greetings,
  notice,
  viewUser,
} from "../Constants";
import { score, scoreLesson, subject, examType, chapter } from "../Constants";
//Avathar Constants API's
import {
  viewAvathar,
  viewAdminAvathar,
  createAvathar,
  updateAvathar,
  selectAvathar,
  deleteAvathar,
} from "../Constants";
import {notification,lesson,selftest} from "../Constants";
//the token is a variable which holds the token
const headers = {
  headers: {
    Authorization: "Bearer " + sessionStorage.getItem("primaryStudentToken"),
    "Content-Type": "application/json",
  },
};

//Login Page
export const primaryStudentLogin = async (requestData) => {
  return await axios
    .post(baseURL + login, requestData, {
      headers,
    })
    .then((requestData) => {
      return requestData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const studentProfile = async () => {
  return await axios
    .post(baseURL + profile, null, headers)
    .then((requestData) => {
      return requestData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const studentViewUser = async (requestData) => {
  return await axios
    .post(baseURL + viewUser, requestData, headers)
    .then((requestData) => {
      return requestData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const studentGreetings = async (requestData) => {
  return await axios
    .post(baseURL + greetings, requestData, headers)
    .then((requestData) => {
      return requestData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const studentNotice = async (requestData) => {
  return await axios
    .post(baseURL + notice, requestData, headers)
    .then((requestData) => {
      return requestData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const dashboardCardScore = async (requestData) => {
  var bodyFormData = new FormData();

  bodyFormData.append("user_id", sessionStorage.loginUser);
  return await axios
    .post(baseURL + score, bodyFormData, headers)
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const lessonScore = async (requestData) => {
  var bodyFormData = new FormData();
  // bodyFormData.append("user_id", sessionStorage.loginUser);
  bodyFormData.append("subject_id", requestData.subject_id);
  bodyFormData.append(" lesson_type_id", requestData.lesson_type_id);
  bodyFormData.append("chapter_id", requestData.chapter_id);
  bodyFormData.append("lesson_id", requestData.lesson_id);
  return await axios
    .post(baseURL + scoreLesson, bodyFormData, headers)
    .then((requestData) => {
      return requestData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const listOfSubjects = async (requestData) => {
  return await axios
    .post(baseURL + subject, requestData, headers)
    .then((requestData) => {
      return requestData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const listOfExamtype = async (requestData) => {
  return await axios
    .post(baseURL + examType, requestData, headers)
    .then((requestData) => {
      return requestData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const listOfChapter = async (requestData) => {
  return await axios
    .post(baseURL + chapter, requestData, headers)
    .then((requestData) => {
      return requestData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const studentViewAdminAvathar = async (requestData) => {
  return await axios
    .post(baseURL + viewAdminAvathar, requestData, headers)
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const studentViewAvathar = async (requestData) => {
  return await axios
    .post(baseURL + viewAvathar, requestData, headers)
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const studentCreateAvathar = async (requestData) => {
  return await axios
    .post(baseURL + createAvathar,requestData, headers)
    
};
export const  studentUpdateAvatar= async(requestData)=>{
  return await axios
  .post(baseURL +updateAvathar,requestData,headers)
}
export const  studentDeleteAvatar=async(requestData)=>{
  return await axios
  .post (baseURL + deleteAvathar,requestData,headers)
  .then((responseData) => {
    return responseData?.data;
  })
  .catch((err) => {
    throw err;
  });
}
export const studentNotification = async (requestData) => {
  return await axios
    .post(baseURL + notification, requestData, headers)
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const studentViewLesson = async (requestData) => {
  return await axios
    .post(baseURL + lesson, requestData, headers)
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const studentSelftest = async (requestData, url) => {
  // const body = {
  //   lesson_id: requestData.lessonId.lessonid,
  // };
  var bodyFormData = new FormData();
  bodyFormData.append("lesson_id", requestData.lessonId.lessonid);

  return await axios
    .post(baseURL + url, bodyFormData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("sqlupToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((responseData) => {
      return responseData?.data;
    })
    .catch((err) => {
      throw err;
    });
};