import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Courses.css";
import ReactPlayer from "react-player";
import { Link, useParams } from "react-router-dom";

import { AiOutlineClose } from "react-icons/ai";
import { MdOutlinePause } from "react-icons/md";
import { BsFileEarmarkText, BsQuestionCircle } from "react-icons/bs";
import { Scrollbars } from "react-custom-scrollbars-2";
import { studentViewLesson, listOfChapter } from "../Services";

const scrollBarStyle = {
  width: "100%",
  height: "25rem",
  color: "#E1E1E1",
};
function LessonView() {
  const [lessontabs, setLessontabs] = useState("selftest");
  const [lessonData, setLessonData] = useState("");
  const [lesson, setLesson] = useState("");
  const { id } = useParams();
  const { chapter } = useParams();

  useEffect(() => {
    studentViewLesson("viewLesson?id=" + id +chapter).then((data) => {
      setLessonData(data);
      if (data[0].video_url != "" && data[0].video_url != null) {
        setLesson(data[1]);
      }
    });
  }, []);

  const lessonViewHandler = (e) => {
    setLessontabs(e);
  };
  const glosaryClose = () => {
    setLessontabs(false);
  };
  return (
    <div className="Lesson_View_Wrapper">
      <div className="container-fluid">
        <div className="col-md-12 d-flex">
          <div className="col-md-6">
            <p>
              {lesson?.number} {lesson?.name}
            </p>
          </div>
          <div className="col-md-6">
            <div className="Lesson_View_right">
              <Link to="/lessons/122/17">
                {" "}
                <button>Back</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="Lesson_View_Video">
          <div className="col-md-12 d-flex">
            <div className="col-md-8">
              <div>
                <ReactPlayer
                  url={lesson?.video_url}
                  className="react-player"
                  style={{ position: "relative", right: "0%" }}
                  width={900}
                  height={400}
                  playing
                  controls
                />
              </div>
              <div className="Buttons_Lesson_View d-flex">
                <Link
                  to="/selftest"
                  onClick={() => lessonViewHandler("selftest")}
                >
                  <button>
                    <i>
                      <BsFileEarmarkText />
                    </i>
                    Self Test
                  </button>
                </Link>
                <button onClick={() => lessonViewHandler("glossary")}>
                  <i>
                    <BsFileEarmarkText />
                  </i>
                  Glossary
                </button>
                <Link to="/qanda" onClick={() => lessonViewHandler("qanda")}>
                  <button id="qandA_button">
                    <i>
                      <BsQuestionCircle />
                    </i>
                    Q&A
                  </button>
                </Link>
              </div>
              <div className="row">
                {lessontabs === "glossary" ? (
                  <div className="Glossary_Editor">
                    <div className="Glossary_table">
                      <div className="col-md-12 Glossary_close">
                        <i onClick={glosaryClose}>
                          <AiOutlineClose />
                        </i>
                      </div>
                    </div>
                    <div className="Lessonview-glosary-dta">
                      <table className="table">
                        <thead>
                          <th>Word</th>
                          <th>Description</th>
                          <th>Example</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Grass</td>
                            <td>Lorem ipsum dolor sit</td>
                            <td>
                              Cow eats <b>Grass</b>
                            </td>
                          </tr>
                          <tr>
                            <td>Well</td>
                            <td>Lorem ipsum dolor sit</td>
                            <td>
                              Put the Coin in the<b>Grass</b>
                            </td>
                          </tr>
                          <tr>
                            <td>Grass</td>
                            <td>Lorem ipsum dolor sit</td>
                            <td>
                              Cow eats <b>Grass</b>
                            </td>
                          </tr>
                          <tr>
                            <td>Grass</td>
                            <td>Lorem ipsum dolor sit</td>
                            <td>
                              Cow eats <b>Grass</b>
                            </td>
                          </tr>
                          <tr>
                            <td>Grass</td>
                            <td>Lorem ipsum dolor sit</td>
                            <td>
                              Cow eats <b>Grass</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="Lesson_Video_List">
                <h5>
                  <img src="\PrimeStudentImages\chapter.svg" alt="C1" />
                  &nbsp; Chapter 1
                </h5>
                <Scrollbars vertical autoHide={false} style={scrollBarStyle}>
                  <div>
                    {lessonData.length > 0 &&
                      lessonData.map((data, index) => (
                        <div>
                          <div
                            className="Lesson_Video_column1 d-flex"
                            style={{
                              backgroundColor: index === 0 ? " #8b60fd" : "",
                              color: index === 0 ? "#fff" : "",
                            }}
                          >
                            <div className="col-md-10">
                              <div className="Lesson_view_Video_List_Left  d-flex">
                                <img
                                  src="\PrimeStudentImages\Number_Blocks_Icon.svg"
                                  alt=""
                                />
                                <div className="Lessonview_Chapter_List1">
                                  <span
                                    style={{
                                      color: index === 0 ? "#fff" : "",
                                    }}
                                  >
                                    Lesson {data?.number}
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      color: index === 0 ? "#fff" : "",
                                    }}
                                  >
                                    {data?.name}
                                  </span>
                                  <br />
                                  <br />
                                  <time>15 Min</time>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="Lesson_view_Video_List_Right1">
                                <img
                                  src="\PrimeStudentImages\Video_Player_Icon.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LessonView;
