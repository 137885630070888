import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";
import { MdClose } from "react-icons/md";
import { AiTwotoneDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Loaders } from "../Helpers/Loaders";
import {
  studentViewAdminAvathar,
  studentViewAvathar,
  studentCreateAvathar,
  studentUpdateAvatar,
  studentDeleteAvatar,
} from "../Services";
function EditAvathar() {
  const [tab, setTab] = useState("sqillupavatar");
  const [fileupload, setFileUpload] = useState(false);
  const [stuAvathar, setStuAvathar] = useState("");
  const [adminAvathar, setAdminAvathar] = useState("");
  const [uploadAvathar, setUploadAvathar] = useState("");
  const [validatemessage, setValidateMessage] = useState();
  const [id, setId] = useState();
  const [popUpupdate, setPopUpUpdate] = useState(false);
  const [validationmsg, setValidationMsg] = useState();
  // const [deleteAvathar, setDeleteAvathaar] = useState(false);
  useEffect(() => {
    studentViewAdminAvathar().then((data) => {
      setAdminAvathar(data);
    });
    studentViewAvathar().then((data) => {
      setStuAvathar(data);
      for(let i of data){
        setId(i?.id);
        setUploadAvathar(i?.avatar);
        // console.log(i?.id);
      }
      // setId(data?.id);
      // setUploadAvathar(data?.avatar);
      // console.log(data[0].id);
      // console.log(data);
    });
  }, []);

  const handelHide = (e) => {
    setTab(e);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setUploadAvathar(base64);
    // console.log(base64);
  };
  //Create Avathar
  const uploadhandelFile = () => {
    setFileUpload(true);
  };
  const avatharCreateUpload = (e) => {
    let FormData = require("form-data");
    let data = new FormData();
    data.append("id", id);
    data.append("avatar", uploadAvathar);
    studentCreateAvathar(data)
      .then((responseData) => {
        setFileUpload(false);
        e.preventDefault();
        window.location.reload(false);
        return responseData?.data;
      })
      .catch((err) => {
        setValidateMessage("please Select jpg and png Images");
      });
  };
  const cancelAvathar = () => {
    setFileUpload(false);
  };
  const updateChangesAvathar = (e) => {
    setPopUpUpdate(true);
  };
  const updateAvathar = (e) => {
    let FormData = require("form-data");
    let data = new FormData();
    data.append("id", id);
    uploadAvathar && data.append("image", uploadAvathar);
    studentUpdateAvatar(data)
      .then((responseData) => {
        setPopUpUpdate(true);
        console.log(responseData);
        e.preventDefault();
        // window.location.reload(false);
        return responseData?.data;    
      })
      .catch((err) => {
        console.error("Problem submitting Update Post", err);
        // setValidationMsg("avatr Updated Successfully", err);
      });
  };
  const canceUpdatelAvathar = (e) => {
    setPopUpUpdate(false);
  };
  const deletehandlerAvathar = (e) => {
    let FormData = require("form-data");
    let data = new FormData();
    data.append("id", id);
    studentDeleteAvatar(data)
      .then((responseData) => {
        setPopUpUpdate(true);
        e.preventDefault();
        window.location.reload(false);
        return responseData?.data;
      })
      .catch((err) => {
        console.error("Problem submitting Delete", err);
        // setValidationMsg("avatr deleted Successfully");
      });
  };
  return (
    <div className="EditAvathar_Wrapper">
      <div className="Close_Avathar">
        <Link to="/home">
          <i>
            <MdClose />
          </i>
        </Link>
      </div>
      <div className="EditAvathar_Button">
        <button onClick={() => handelHide("sqillupavatar")} id="Sqillup_Avatar">
          Sqillup Avatar
        </button>
        <button onClick={() => handelHide("studentavatar")} id="Student_Avatar">
          Student Avatar
        </button>
      </div>
      <div className="container-fluid">
        <div className="Avatar_Wrapper">
          {tab === "sqillupavatar" ? (
            <div>
              <div className="col-md-12 Sqillupavatar_img d-flex">
                {adminAvathar.length > 0 &&
                  adminAvathar.map((adminavthar, index) => (
                    <div className="col-md-2">
                      <img src={adminavthar?.image} alt="" />
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            ""
          )}
          {tab === "studentavatar" ? (
            <div className="row">
              <div className="col-md-12">
                <div className=" Sqillupavatar_img d-flex">
                  {stuAvathar.length > 0 &&
                    stuAvathar.map((avathar, index) => (
                      <div className="col-md-2 col-sm-2 col-lg-2 col-xs-2">
                        <img
                          src={avathar?.avatar}
                          alt=""
                          onClick={updateChangesAvathar}
                        />
                      </div>
                    ))}
                  <br />
                  <div className="col-md-2" style={{ cursor: "pointer" }}>
                    <img
                      src="\PrimeStudentImages\plus-circle.svg"
                      alt=""
                      onClick={uploadhandelFile}
                      onChange={(e) => uploadImage(e)}

                      // value={uploadImage}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="Avatar_Buttons">
        <button>Save</button>
      </div>
      {fileupload ? (
        <div className="open_file_container container-fluid ">
          <div className="Upload_file_top ">
            <div className="col-md-12  d-flex">
              <div className="col-md-6">
                <h6>Upload Avatar</h6>
              </div>
            </div>
          </div>
          <div className="upload_file_input">
            <div className="col-md-12">
              <input
                type="file"
                className="form-control"
                id="formFile"
                onChange={(e) => uploadImage(e)}
                // value={uploadAvathar}
              />{" "}
              <span style={{ color: "red", paddingLeft: "2rem" }}>
                {validatemessage}
              </span>
            </div>
          </div>
          <div className="Upload_file_buttons d-md-flex justify-content-md-end">
            <button id="cancel" onClick={cancelAvathar}>
              Cancel
            </button>
            <button id="save" onClick={avatharCreateUpload}>
              Save
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      {popUpupdate ? (
        <div className="open_file_container container-fluid ">
          <div className="Upload_file_top ">
            <div className="col-md-12  d-flex">
              <div className="col-md-6">
                <h6>Upload Avatar</h6>
              </div>
              <div className="col-md-6">
                <h5
                  onClick={deletehandlerAvathar}
                  style={{ cursor: "pointer" }}
                >
                  <AiTwotoneDelete />
                </h5>
                <span style={{ color: "red", paddingLeft: "2rem" }}>
                  {validationmsg}
                </span>
              </div>
            </div>
          </div>
          <div className="upload_file_input">
            <div className="col-md-12">
              <input type="hidden" id="id" name="id" value={id} />
              <input
                type="file"
                className="form-control"
                id="formFile"
                onChange={(e) => uploadImage(e)}
                // value={uploadAvathar}
              />{" "}
              <span style={{ color: "red", paddingLeft: "2rem" }}>
                {validationmsg}
              </span>
            </div>
          </div>
          <div className="Upload_file_buttons d-md-flex justify-content-md-end">
            <button id="cancel" onClick={canceUpdatelAvathar}>
              Cancel
            </button>
            <button id="save" onClick={updateAvathar}>
              Update
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default EditAvathar;
