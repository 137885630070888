import React, { useState, useEffect } from "react";
import SnowMen from "../LottieJsonFiles/SnowMen.json";
import Lottie from "lottie-react";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";
import { studentViewUser } from "../Services";

function HomeLeft() {
  // const [value, onChange] = useState(new Date());
  const [user, serUser] = useState("");
  useEffect(() => {
    studentViewUser().then((data) => {
      serUser(data);
    });
  }, []);
  return (
    <div className="col-md-12">
      <div className="Right_Side_calender">
        <div className="">
          <div className="Background_Image">
            <img src="\PrimeStudentImages\Background_Icon.svg" alt="" />
          </div>
          <div className="col-md-6">
            <div className="Left_Side_iamges">
              <i id="ConicalFlask">
                <img src="\PrimeStudentImages\Subject_icon.svg" alt="" />
              </i>
            </div>
          </div>
          <div className="col-md-6">
            <div className="Right_side_text">
              <span>SqillUP</span>
              <p>Science & Maths</p>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="SnowMen_Data  d-flex">
            <Lottie animationData={SnowMen} />
            <Link to="/editavathar">
              <i>
                <BiEdit />
              </i>
            </Link>
          </div>
          <h5 id="Profile_Name">
            {user.first_name}
            &nbsp;
            {user.last_name}
          </h5>
        </div>
        <div className="calender">
          {/* <Calendar onChange={onChange} value={value} /> */}
          <img src="\PrimeStudentImages\Calender.svg" alt="" />
        </div>
      </div>
    </div>
  );
}

export default HomeLeft;
