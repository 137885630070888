import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SigninPage from "./Components/SigninPage";
// import Form from "./FormValidations/Form";
import Home from "./Components/Home";
import ProgressbarViewAll from "./Home/ProgressbarViewAll";
import ScoreViewAll from "./Home/ScoreViewAll";
import EditAvathar from "./Home/EditAvathar";
//Leftbar Components
import Navbars from "./Pages/Navbars";
import Courses from "./Components/Courses";
import Lessons from "./Courses/Lessons";
import LessonView from "./Courses/LessonView";
import Selftest from "./Courses/Selftest";
import QandA from "./Courses/QandA";
import Pastpaper from "./Components/Pastpaper";
import SeasonActivity from "./Components/SeasonActivity";
import MyPracticeTest from "./Components/MyPracticeTest";
import SubmitForm from "./MyPracticeTest/SubmitForm";
import Activity from "./MyPracticeTest/Activity";
import ViewSolution from "./MyPracticeTest/ViewSolution";
import Revision from "./Components/Revision";
import RevisionSelftest from "./Revision/RevisionSelftest";
import Curriculum from "./Components/Curriculum";
import ChapterGuidline from "./Curriculum/ChapterGuidline";
import Swap from "./Components/Swap";
import ContactUs from "./Components/ContactUs";
import FAQ from "./Components/FAQ";
//Topbar Components
import Notifications from "./Components/Notifications";
import Badges from "./Components/Badges";
import MyProfile from "./Components/MyProfile";
import Signout from "./Components/Signout";
// Extra Pages
import Selftest2 from "./Selftest/Selftest2";

const NavRoute = ({ component: Component, ...rest }) => (
  <div>
    <Navbars />
    <Component />
  </div>
);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Form />} /> */}
          <Route path="/" element={<SigninPage />} />
          <Route path="/home" element={<NavRoute component={Home} />} />
          <Route
            path="/progressbar"
            element={<NavRoute component={ProgressbarViewAll} />}
          />

          <Route
            path="/scoreview"
            element={<NavRoute component={ScoreViewAll} />}
          />
          <Route path="/editavathar" element={<EditAvathar />} />
          <Route path="/courses" element={<NavRoute component={Courses} />} />
          <Route
            path="/lessons/:id/:subject"
            element={<NavRoute component={Lessons} />}
          />
          {/* <Route
            path="/lessons"
            element={<NavRoute component={Lessons} />}
          />  */}
          <Route
            path="/lessonview/:id/:chapter"
            element={<NavRoute component={LessonView} />}
          />
          <Route path="/selftest" element={<Selftest />} />
          <Route path="/selftest2" element={<Selftest2 />} />
          <Route path="/qanda" element={<QandA />} />
          <Route
            path="/pastpaper"
            element={<NavRoute component={Pastpaper} />}
          />
          <Route
            path="/seasonactivity"
            element={<NavRoute component={SeasonActivity} />}
          />
          <Route
            path="/practicetest"
            element={<NavRoute component={MyPracticeTest} />}
          />
          <Route path="/revision" element={<NavRoute component={Revision} />} />
          <Route path="/revisionselftest" element={<RevisionSelftest />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/submit" element={<SubmitForm />} />
          <Route path="/viewsolution" element={<ViewSolution />} />

          <Route
            path="/curriculum"
            element={<NavRoute component={Curriculum} />}
          />
          <Route
            path="/curriculumguidline"
            element={<NavRoute component={ChapterGuidline} />}
          />
          <Route path="/swap" element={<Swap />} />
          <Route
            path="/contactus"
            element={<NavRoute component={ContactUs} />}
          />
          <Route path="/faq" element={<NavRoute component={FAQ} />} />
          <Route
            path="/notifications"
            element={<NavRoute component={Notifications} />}
          />
          <Route path="/badges" element={<NavRoute component={Badges} />} />
          <Route
            path="/myprofile"
            element={<NavRoute component={MyProfile} />}
          />
          <Route path="/signout" element={<NavRoute component={Signout} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
