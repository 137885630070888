import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Seasonactivity.css";
import Snow from "../LottieJsonFiles/Snow.json";
import Snowmen1 from "../LottieJsonFiles/Snowmen1.json";
import Lottie from "lottie-react";

function Winter() {
  const [summertab, setSummerTab] = useState("week1");
  const [selftestSummer, setSelftestSummer] = useState(false);
  const summerActiveHandler = (e) => {
    setSummerTab(e);
  };
  const selftestSummerhandel = (e) => {
    setSelftestSummer(true);
  };
  return (
    <div className="Winter_Season">
    <div className="Winter_Season_weeks">
      <button id="active" onClick={() => summerActiveHandler("week1")}>
        Week 1
      </button>
      <button onClick={() => summerActiveHandler("week2")}>Week 2</button>
      <button onClick={() => summerActiveHandler("week3")}>Week 3</button>
      <button onClick={() => summerActiveHandler("week4")}>Week 4</button>
      <button onClick={() => summerActiveHandler("week5")}>Week 5</button>
    </div>
    <div className="Winter_Week_tabs">
      {summertab === "week1" ? (
        <div className="Winter_season_week_Test">
          <div className="Winter_week_tab">
            <div className="Winter_week_Name_tab">
              <h4>Week 1</h4>
            </div>
            <div className="Summer_Lottie">
              <Lottie animationData={Snow} />
            </div>
            <div className="Winter_Week_table_data">
              <div className="d-flex">
                <p>
                  Mon 01 <sup>st</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start" onClick={selftestSummerhandel}>
                  Start
                </button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Tue 02<sup>nd</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Wed 03<sup>rd</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Mon 04<sup>th</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Mon 05<sup>th</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
            </div>
            <div className="Sunflower">
              <Lottie animationData={Snowmen1} />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {summertab === "week2" ? (
        <div className="Winter_season_week_Test">
          <div className="Winter_week_tab">
            <div className="Winter_week_Name_tab">
              <h4>Week 2</h4>
            </div>
            <div className="Summer_Lottie">
              <Lottie animationData={Snow} />
            </div>
            <div className="Winter_Week_table_data">
              <div className="d-flex">
                <p>
                  Mon 01 <sup>st</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start" onClick={selftestSummerhandel}>
                  Start
                </button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Tue 02<sup>nd</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Wed 03<sup>rd</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Mon 04<sup>th</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Mon 05<sup>th</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
            </div>
            <div className="Sunflower">
              <Lottie animationData={Snowmen1} />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {summertab === "week3" ? (
        <div className="Winter_season_week_Test">
          <div className="Winter_week_tab">
            <div className="Winter_week_Name_tab">
              <h4>Week 3</h4>
            </div>
            <div className="Summer_Lottie">
              <Lottie animationData={Snow} />
            </div>
            <div className="Winter_Week_table_data">
              <div className="d-flex">
                <p>
                  Mon 01 <sup>st</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start" onClick={selftestSummerhandel}>
                  Start
                </button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Tue 02<sup>nd</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Wed 03<sup>rd</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Mon 04<sup>th</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Mon 05<sup>th</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
            </div>
            <div className="Sunflower">
              <Lottie animationData={Snowmen1} />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {summertab === "week4" ? (
        <div className="Winter_season_week_Test">
          <div className="Winter_week_tab">
            <div className="Winter_week_Name_tab">
              <h4>Week 4</h4>
            </div>
            <div className="Summer_Lottie">
              <Lottie animationData={Snow} />
            </div>
            <div className="Winter_Week_table_data">
              <div className="d-flex">
                <p>
                  Mon 01 <sup>st</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start" onClick={selftestSummerhandel}>
                  Start
                </button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Tue 02<sup>nd</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Wed 03<sup>rd</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Mon 04<sup>th</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Mon 05<sup>th</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
            </div>
            <div className="Sunflower">
              <Lottie animationData={Snowmen1} />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {summertab === "week5" ? (
        <div className="Winter_season_week_Test">
          <div className="Winter_week_tab">
            <div className="Winter_week_Name_tab">
              <h4>Week 5</h4>
            </div>
            <div className="Summer_Lottie">
              <Lottie animationData={Snow} />
            </div>
            <div className="Winter_Week_table_data">
              <div className="d-flex">
                <p>
                  Mon 01 <sup>st</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start" onClick={selftestSummerhandel}>
                  Start
                </button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Tue 02<sup>nd</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Wed 03<sup>rd</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Mon 04<sup>th</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
              <div className="d-flex">
                <p>
                  Mon 05<sup>th</sup>
                </p>
                <span>
                  ..................................................
                </span>
                <button id="start">Start</button>
                <button id="redo">Redo</button>
                <button id="time">00</button>
              </div>
            </div>
            <div className="Sunflower">
              <Lottie animationData={Snowmen1} />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  </div>
  );
}

export default Winter;
