import React from "react";
import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { AiFillEye, AiOutlineSearch } from "react-icons/ai";
function Topbar() {
  return (
    <>
      <nav className="navbar_swap navbar-expand-lg ">
        <div className="container-fluid ">
          <div className="col-lg-12 col-md-12 col-sm-8 col-xs-6 d-flex">
            <div className="col-md-6">
              <div >
                <form className="d-flex Searchbar">
                  <div className="serch-icon">
                    <AiOutlineSearch />
                  </div>
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search for anything (Class, Subjects, Exams .etc)"
                    aria-label="Search"
                  />
                </form>
              </div>
            </div>
            <div className="d-flex Right-Side-align">
              <div className="col-md-2">
                <div className="d-md-flex justify-md-content-end">
                  <div className="Notifications">
                    <Link to="#">
                      <img
                        src="\PrimeStudentImages\Notificaion_Icon.svg"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-md-flex justify-md-content-end">
                <div className="profile-dropdown_swap ">
                  <ul className="navbar-nav ">
                    <li className="nav-item Profile_swap">
                      <img src="\PrimeStudentImages\User_Profile.png" alt="" />
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        to="#"
                        className="nav-link myprofile_top_data"
                        data-toggle="dropdown"
                        title="Login User"
                      >
                        <p className="dropdown-toggle">
                          Hello, <b>Lucifer</b>
                        </p>
                        <span>Student</span>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right top-user">
                        <div className="profile_dropdown_image d-flex">
                          <img
                            src="\PrimeStudentImages\User_Profile@2x.png"
                            alt=""
                          />
                          <Link to="#">
                            {" "}
                            <i>
                              <MdClose />
                            </i>
                          </Link>
                        </div>
                        <div className="Profile_View_swap">
                          <p>Lucifer Morn</p>
                          <Link to="#">
                            <button>
                              <i>
                                <AiFillEye />
                              </i>{" "}
                              View Profile
                            </button>
                          </Link>
                        </div>
                        <div className="SignOut_Page">
                          <Link to="/signout">
                            {" "}
                            <button>Sign out</button>
                          </Link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Topbar;
