import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Homepage.css";
import { Link } from "react-router-dom";

function Leftbar() {
  const activeListHandler = () => {
    const list = document.querySelectorAll(".list");
    function activeLink() {
      list.forEach((item) => item.classList.remove("active"));
      this.classList.add("active");
    }
    list.forEach((item) => item.addEventListener("click", activeLink));
  };

  return (
    <>
      <div className="aside">
        <div className="Sidebar">
          <div className="navbar-brand">
            <img src="\PrimeStudentImages\Logo_prime.svg" alt="" />
          </div>
          <div className="Sidebar_Icons">
            <ul onClick={() => activeListHandler()}>
              <li className="list active">
                <Link to="/home">
                  <span className="Icon">
                    <img src="\PrimeStudentImages\Home_Icon.jpg" alt="" />
                  </span>
                  <span className="title">Home</span>
                </Link>
              </li>
              <li className="list">
                <Link to="/courses">
                  <span className="Icon">
                    <img src="\PrimeStudentImages\Courses_Icon.svg" alt="" />
                  </span>
                  <span className="title">Courses</span>
                </Link>
              </li>
              <li className="list">
                <Link to="/pastpaper">
                  <span className="Icon">
                    <img src="\PrimeStudentImages\Pastpaper_Icon.svg" alt="" />
                  </span>
                  <span className="title">Past Paper</span>
                </Link>
              </li>
              <li className="list">
                <Link to="/seasonactivity">
                  <span className="Icon">
                    <img
                      src="\PrimeStudentImages\Seasonactivity_Icon.svg"
                      alt=""
                    />
                  </span>
                  <span className="title">Season Activity</span>
                </Link>
              </li>
              <li className="list">
                <Link to="/practicetest">
                  <span className="Icon">
                    <img
                      src="\PrimeStudentImages\Practicetest_Icon.svg"
                      alt=""
                    />
                  </span>
                  <span className="title">My practice test</span>
                </Link>
              </li>
              <li className="list">
                <Link to="/revision">
                  <span className="Icon">
                    <img src="\PrimeStudentImages\Revision_Icon.svg" alt="" />
                  </span>
                  <span className="title">Revision</span>
                </Link>
              </li>
              <li className="list">
                <Link to="/curriculum">
                  <span className="Icon">
                    <img src="\PrimeStudentImages\Curriculum_Icon.svg" alt="" />
                  </span>
                  <span className="title">Curriculum</span>
                </Link>
              </li>
              <br />
              <br />
              <br />
              <li className="list">
                <Link to="/swap">
                  <span className="Icon">
                    <img src="\PrimeStudentImages\Swap_Icon.svg" alt="" />
                  </span>
                  <span className="title">Swap</span>
                </Link>
              </li>
              <li className="list">
                <Link to="/contactus">
                  <span className="Icon">
                    <img src="\PrimeStudentImages\ContactUs_Icon.svg" alt="" />
                  </span>
                  <span className="title">Contact Us</span>
                </Link>
              </li>
              <li className="list">
                <Link to="/faq">
                  <span className="Icon">
                    <img src="\PrimeStudentImages\Faq_Icon.svg" alt="" />
                  </span>
                  <span className="title">FAQ</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Leftbar;
