import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Seasonactivity.css";
import SummerLottie from "../LottieJsonFiles/SummerLottie.json";
import Sunflower from "../LottieJsonFiles/Sunflower.json";
import Lottie from "lottie-react";
function Summer() {
  const [summertab, setSummerTab] = useState("week1");
  const [selftestSummer, setSelftestSummer] = useState(false);
  const summerActiveHandler = (e) => {
    setSummerTab(e);
  };
  const selftestSummerhandel = (e) => {
    setSelftestSummer(true);
  };

  return (
    <div className="Summer_Season">
      <div className="Summer_Season_weeks">
        <button id="active" onClick={() => summerActiveHandler("week1")}>
          Week 1
        </button>
        <button onClick={() => summerActiveHandler("week2")}>Week 2</button>
        <button onClick={() => summerActiveHandler("week3")}>Week 3</button>
        <button onClick={() => summerActiveHandler("week4")}>Week 4</button>
        <button onClick={() => summerActiveHandler("week5")}>Week 5</button>
      </div>
      <div className="Summer_Week_tabs">
        {summertab === "week1" ? (
          <div className="season_week_Test">
            <div className="week_tab">
              <div className="week_Name_tab">
                <h4>Week 1</h4>
              </div>
              <div className="Summer_Lottie">
                <Lottie animationData={SummerLottie} />
              </div>
              <div className="Week_table_data">
                <div className="d-flex">
                  <p>
                    Mon 01 <sup>st</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start" onClick={selftestSummerhandel}>
                    Start
                  </button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Tue 02<sup>nd</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Wed 03<sup>rd</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Mon 04<sup>th</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Mon 05<sup>th</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
              </div>
              <div className="Sunflower">
                <Lottie animationData={Sunflower} />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {summertab === "week2" ? (
          <div className="season_week_Test">
            <div className="week_tab">
              <div className="week_Name_tab">
                <h4>Week 2</h4>
              </div>
              <div className="Summer_Lottie">
                <Lottie animationData={SummerLottie} />
              </div>
              <div className="Week_table_data">
                <div className="d-flex">
                  <p>
                    Mon 01 <sup>st</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start" onClick={selftestSummerhandel}>
                    Start
                  </button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Tue 02<sup>nd</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Wed 03<sup>rd</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Mon 04<sup>th</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Mon 05<sup>th</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
              </div>
              <div className="Sunflower">
                <Lottie animationData={Sunflower} />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {summertab === "week3" ? (
          <div className="season_week_Test">
            <div className="week_tab">
              <div className="week_Name_tab">
                <h4>Week 3</h4>
              </div>
              <div className="Summer_Lottie">
                <Lottie animationData={SummerLottie} />
              </div>
              <div className="Week_table_data">
                <div className="d-flex">
                  <p>
                    Mon 01 <sup>st</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start" onClick={selftestSummerhandel}>
                    Start
                  </button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Tue 02<sup>nd</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Wed 03<sup>rd</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Mon 04<sup>th</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Mon 05<sup>th</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
              </div>
              <div className="Sunflower">
                <Lottie animationData={Sunflower} />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {summertab === "week4" ? (
          <div className="season_week_Test">
            <div className="week_tab">
              <div className="week_Name_tab">
                <h4>Week 4</h4>
              </div>
              <div className="Summer_Lottie">
                <Lottie animationData={SummerLottie} />
              </div>
              <div className="Week_table_data">
                <div className="d-flex">
                  <p>
                    Mon 01 <sup>st</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start" onClick={selftestSummerhandel}>
                    Start
                  </button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Tue 02<sup>nd</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Wed 03<sup>rd</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Mon 04<sup>th</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Mon 05<sup>th</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
              </div>
              <div className="Sunflower">
                <Lottie animationData={Sunflower} />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {summertab === "week5" ? (
          <div className="season_week_Test">
            <div className="week_tab">
              <div className="week_Name_tab">
                <h4>Week 5</h4>
              </div>
              <div className="Summer_Lottie">
                <Lottie animationData={SummerLottie} />
              </div>
              <div className="Week_table_data">
                <div className="d-flex">
                  <p>
                    Mon 01 <sup>st</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start" onClick={selftestSummerhandel}>
                    Start
                  </button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Tue 02<sup>nd</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Wed 03<sup>rd</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Mon 04<sup>th</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
                <div className="d-flex">
                  <p>
                    Mon 05<sup>th</sup>
                  </p>
                  <span>
                    ..................................................
                  </span>
                  <button id="start">Start</button>
                  <button id="redo">Redo</button>
                  <button id="time">00</button>
                </div>
              </div>
              <div className="Sunflower">
                <Lottie animationData={Sunflower} />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Summer;
