import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Swap.css";
import { Link } from "react-router-dom";
function Leftbar() {
  return (
    <>
      <div className="aside">
        <div className="Sidebar">
          <div className="navbar-brand">
            <img src="\PrimeStudentImages\Logo_prime.svg" alt="" />
          </div>
          <div className="Sidebar_Swap">
            <ul>
              <li>
                <Link to="#">
                  <img
                    src="\PrimeStudentImages\Home_Icon.jpg"
                    alt=""
                    data-text="Home"
                  />
                  <span className="nav-text">Home</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img
                    src="\PrimeStudentImages\Two_user.svg"
                    alt=""
                    data-text="Student Profile"
                  />
                  <span className="nav-text">StudentProfile</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img
                    src="\PrimeStudentImages\secure.svg"
                    alt=""
                    data-text="Growth"
                  />

                  <span className="nav-text">Growth</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img
                    src="\PrimeStudentImages\Practicetest_Icon.svg"
                    alt=""
                    data-text="Activity"
                  />
                  <span className="nav-text">Activity</span>
                </Link>
              </li>
              <br />
              <br />
              <br />
              <li>
                <Link to="/home">
                  <img
                    src="\PrimeStudentImages\Swap_Icon.svg"
                    alt=""
                    data-text="Swap"
                  />
                  <span className="nav-text">Swap </span>
                  {/* <span className="title">Swap</span> */}
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img
                    src="\PrimeStudentImages\Help.svg"
                    alt=""
                    data-text="Contact Us"
                  />
                  <span className="nav-text">ContactUs</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img
                    src="\PrimeStudentImages\FAQ.svg"
                    alt=""
                    data-text="Help"
                  />
                  <span className="nav-text">Help</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Leftbar;
