import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/FAQ.css";
import { AiOutlinePlusCircle } from "react-icons/ai";
import $ from "jquery";

function FAQ() {
  const activeHandler = () => {
    $(document).ready(function () {
      $(".accordion h6").click(function () {
        $(this).toggleClass("active");
        $(this).next(".content").slideToggle(400);
      });
    });
  };
  return (
    <>
      <div className="FAQ_wrapper">
        <div className="container-fluid">
          <h5>FAQ</h5>
          <div className="col-md-12 d-flex">
            <div className="col-md-9">
              <div className="container" onClick={activeHandler}>
                <div className="accordion">
                  <div className="accordion-item">
                    <h6>
                      lorem ipsum dolor sit amet?{" "}
                      <i>
                        <AiOutlinePlusCircle />
                      </i>
                    </h6>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Elementum sagittis vitae et leo duis ut.
                        Ut tortor pretium viverra suspendisse potenti.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h6>
                      lorem ipsum dolor sit amet?{" "}
                      <i>
                        <AiOutlinePlusCircle />
                      </i>
                    </h6>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Elementum sagittis vitae et leo duis ut.
                        Ut tortor pretium viverra suspendisse potenti.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h6>
                      lorem ipsum dolor sit amet?{" "}
                      <i>
                        <AiOutlinePlusCircle />
                      </i>
                    </h6>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Elementum sagittis vitae et leo duis ut.
                        Ut tortor pretium viverra suspendisse potenti.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h6>
                      lorem ipsum dolor sit amet?{" "}
                      <i>
                        <AiOutlinePlusCircle />
                      </i>
                    </h6>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Elementum sagittis vitae et leo duis ut.
                        Ut tortor pretium viverra suspendisse potenti.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="faq_textarea">
                <h6>
                  Can't find your question here? Contact us at
                  <a href="mailto:contact@sqillup.uk.com">
                    {" "}
                    contact@sqillup.uk.com
                  </a>
                </h6>
                <strong>OR</strong>
                <textarea
                  class="form-control"
                  placeholder="Type Message"
                  rows="4"
                />
                <div className="querry_button">
                  <button>Submit querry</button>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="faq_right_side">
                <img src="\PrimeStudentImages\faq_img.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ;
