import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";
import GiftBox from "../LottieJsonFiles/GiftBox.json";
import AbcdBoard from "../LottieJsonFiles/AbcdBoard.json";
import Lottie from "lottie-react";
import { studentProfile } from "../Services";
function MyProfile() {
  const [stuProfile, setStuProfile] = useState("");
  const [spinner, setSpinner] = useState(false);
  useEffect(() => {
    studentProfile().then((profile) => {
      setStuProfile(profile);
    });
    setSpinner(true);
    setTimeout(() => {
      setSpinner(false);
    }, 1000);
  }, []);
  return (
    <>
      <div className="MyProfile_Wrapper">
        <div className="container-fluid">
          {spinner ? (
            <div className="loader-container">
              <div className="spinner">{/* <Loaders /> */}</div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="MyProfile_Form_data  d-flex">
                <div className="col-md-2">
                  {/* <img src="\PrimeStudentImages\User_Profile@2x.png" alt="" /> */}
                  <img src={stuProfile.image} alt={stuProfile.full_name} />
                </div>
                <div className="col-md-8">
                  <div className="MyProfile_Form">
                    <h6>Basic Details</h6>
                    <form className="d-flex">
                      <div className="col-md-6">
                        <label>
                          Name
                          <input type="text" value={stuProfile.full_name} />
                        </label>
                        <label>
                          School Name
                          <input
                            type="text"
                            value={stuProfile.student_detail?.school_name}
                          />
                        </label>
                        <label>
                          Exam Board
                          <input
                            type="text"
                            value={stuProfile.student_detail?.exam_board.name}
                          />
                        </label>
                        <label>
                          Class
                          <input
                            type="text"
                            value={stuProfile.student_detail?.grade.name}
                          />
                        </label>
                        <label>
                          Country
                          <input type="text" value={stuProfile.country?.name} />
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label>
                          Gender
                          <input type="text" value={stuProfile.gender} />
                        </label>
                        <label>
                          Birthday
                          <input
                            type="text"
                            value={stuProfile.student_detail?.dob}
                          />
                        </label>
                        <label>
                          Key Stage
                          <input
                            type="text"
                            value={stuProfile.student_detail?.key_stage.name}
                          />
                        </label>
                        <label>
                          Address
                          <input type="text" value={stuProfile.address} />
                        </label>
                        <label>
                          City
                          <input type="text" value={stuProfile.city} />
                        </label>
                      </div>
                    </form>
                    <br />
                  </div>
                  <div className="AbcsBoard">
                    <Lottie animationData={AbcdBoard} />
                  </div>
                  <div className="GiftBox">
                    <Lottie animationData={GiftBox} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MyProfile;
