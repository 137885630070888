import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Courses.css";
import { MdAccessTimeFilled, MdVolumeUp } from "react-icons/md";
import { Link } from "react-router-dom";

function RevisionSelftest() {
  return (
    <>
      <body className="postid-36782 ">
        <div className="Selftest_Wrapper">
          <div className="container">
            <div className="col-md-12 d-flex">
              <div className="col-md-2">
                <div className="Selftest_Time">
                  <button>
                    <MdAccessTimeFilled />
                    &nbsp;
                    <time>01.00</time>
                  </button>
                </div>
              </div>
              <div className="col-md-8">
                <div class="pagination">
                  <a href="#" className="blocks active">
                    1
                  </a>
                  <a href="#" className="blocks">
                    2
                  </a>
                  <a href="#" className="blocks">
                    3
                  </a>
                  <a href="#" className="blocks">
                    4
                  </a>
                  <a href="#" className="blocks">
                    5
                  </a>
                  <a href="#" className="blocks">
                    6
                  </a>
                  <a href="#" className="blocks">
                    7
                  </a>
                  <a href="#" className="blocks">
                    8
                  </a>
                  <a href="#" className="blocks">
                    9
                  </a>
                  <a href="#" className="blocks">
                    10
                  </a>
                </div>
              </div>
              <div className="col-md-2">
                <div className="Selftest_Back_button">
                  <Link to="/revision">
                    {" "}
                    <button>Close</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="Selftest_body">
              <div className="Selftest_Question">
                <p>
                  Q1.
                  <i>
                    <MdVolumeUp />
                  </i>{" "}
                  Ben ate half a Pizza
                </p>
                <h5>Which fraction shows the amount he ate</h5>
                <h6>Select the correct box.</h6>

                <img src="\PrimeStudentImages\Half Pizza.svg" alt="C1" />
              </div>
              <div className="Selftest_Options">
                <button>A. 1/2</button> <button>B. 3/2</button>
              </div>
              <div className="Selftest_Options">
                <button>C. 3/6</button> <button>D. 2/2</button>
              </div>
              <div className="Close_Button_Selftest">
                <Link to="/selftest2">
                  <button>Next</button>
                </Link>
              </div>
            </div>
            <div className="Selftest_bottom_Chapter">
              <span>Chapter 1: 1.1 addition and subtraction</span>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}

export default RevisionSelftest;
