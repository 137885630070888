import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

function ProgressbarViewAll() {
  const now = 70;
  const [hideprofile, setHideProfile] = useState(false);

  const progressbarNextLessons = () => {
    setHideProfile(true);
  };
  const progressbarPreviousLessons = () => {
    setHideProfile(false);
  };
  return (
    <div className="ProgressbarViewAll_wrapper">
      <div className="container-fluid">
        <div className="Progressbar_Dropdown">
          <select
            className="form-control dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <option id="math">Math</option>
            <option id="science">Science</option>
          </select>
        </div>
        {hideprofile ? (
          <div className="Progressbar_Design">
            <div className="col-md-12  d-flex">
              <div className="col-md-4">
                <div className="ProgressBar_C1 d-flex">
                  <div className="col-md-2 d-flex">
                    <img
                      src="\PrimeStudentImages\Progressbar_Icon.svg"
                      alt="C1"
                    />
                    <div>
                      {" "}
                      <span>C4</span>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <ProgressBar
                      animated
                      now={70}
                      style={{ width: "90%" }}
                      label={`${now}%`}
                    />
                  </div>
                </div>
                <div className="ProgressBar_Card_View d-flex">
                  <div className="col-md-2">
                    <img src="\PrimeStudentImages\Green_Icon.png" alt="C1" />
                    {/* <span className="active">C4</span> */}
                  </div>
                  <div className="col-md-10">
                    <p>1.1 Addition and subtraction</p>
                  </div>
                </div>
                <div className="ProgressBar_Card_View d-flex">
                  <div className="col-md-2">
                    <img
                      src="\PrimeStudentImages\Grey_Icon.png"
                      alt="C1"
                      className="Inactive"
                    />
                    {/* <span className="inActive">C4</span> */}
                    {/* <img src="\PrimeStudentImages\coin.svg" alt="C1" /> */}
                  </div>
                  <div className="col-md-10">
                    <p>1.2 multiplication and division and etc</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ProgressBar_C2 d-flex">
                  <div className="col-md-2 d-flex">
                    <img
                      src="\PrimeStudentImages\Progressbar_Icon.svg"
                      alt="C1"
                    />
                    <div>
                      {" "}
                      <span>C5</span>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <ProgressBar
                      animated
                      now={70}
                      style={{ width: "90%" }}
                      label={`${now}%`}
                    />
                  </div>
                </div>
                <div className="ProgressBar_Card_View d-flex">
                  <div className="col-md-2">
                    {/* <img src="#" alt="C1" className="active"/> */}
                    <img src="\PrimeStudentImages\Green_Icon.png" alt="C1" />
                    {/* <span className="active">C5</span> */}
                  </div>
                  <div className="col-md-10">
                    <p>2.1 Addition and subtraction</p>
                  </div>
                </div>
                <div className="ProgressBar_Card_View d-flex">
                  <div className="col-md-2">
                    <img
                      src="\PrimeStudentImages\Grey_Icon.png"
                      alt="C1"
                      className="Inactive"
                    />
                    {/* <span className="inActive">C5</span> */}
                  </div>
                  <div className="col-md-10">
                    <p>2.2 multiplication and division and etc</p>
                  </div>
                </div>
              </div>
            </div>
            <i onClick={progressbarPreviousLessons} id="iconScroll">
              <FaAngleLeft />
            </i>
          </div>
        ) : (
          <div className="Progressbar_Design">
            <div className="col-md-12  d-flex">
              <div className="col-md-4">
                <div className="ProgressBar_C1 d-flex">
                  <div className="col-md-2 d-flex">
                    <img
                      src="\PrimeStudentImages\Progressbar_Icon.svg"
                      alt="C1"
                    />
                    <div>
                      {" "}
                      <span>C1</span>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <ProgressBar
                      animated
                      now={70}
                      style={{ width: "90%" }}
                      label={`${now}%`}
                    />
                  </div>
                </div>
                <div className="ProgressBar_Card_View d-flex">
                  <div className="col-md-2">
                    {/* <span className="active">C1</span> */}
                    <img src="\PrimeStudentImages\Green_Icon.png" alt="C1" />
                  </div>
                  <div className="col-md-10">
                    <p>1.1 Addition and subtraction</p>
                  </div>
                </div>
                <div className="ProgressBar_Card_View d-flex">
                  <div className="col-md-2">
                    <img
                      src="\PrimeStudentImages\Grey_Icon.png"
                      alt="C1"
                      className="Inactive"
                    />
                    {/* <span className="inActive">C1</span> */}
                  </div>
                  <div className="col-md-10">
                    <p>1.2 multiplication and division and etc</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ProgressBar_C2 d-flex">
                  <div className="col-md-2 d-flex">
                    <img
                      src="\PrimeStudentImages\Progressbar_Icon.svg"
                      alt="C1"
                    />
                    <div>
                      {" "}
                      <span>C2</span>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <ProgressBar
                      animated
                      now={70}
                      style={{ width: "90%" }}
                      label={`${now}%`}
                    />
                  </div>
                </div>
                <div className="ProgressBar_Card_View d-flex">
                  <div className="col-md-2">
                    <img src="\PrimeStudentImages\Green_Icon.png" alt="C1" />
                    {/* <span className="active">C2</span> */}
                  </div>
                  <div className="col-md-10">
                    <p>2.1 Addition and subtraction</p>
                  </div>
                </div>
                <div className="ProgressBar_Card_View d-flex">
                  <div className="col-md-2">
                    <img
                      src="\PrimeStudentImages\Grey_Icon.png"
                      alt="C1"
                      className="Inactive"
                    />
                    {/* <span className="inActive">C2</span> */}
                  </div>
                  <div className="col-md-10">
                    <p>2.2 multiplication and division and etc</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ProgressBar_C3 d-flex">
                  <div className="col-md-2 d-flex">
                    <img
                      src="\PrimeStudentImages\Progressbar_Icon.svg"
                      alt="C1"
                    />
                    <div>
                      {" "}
                      <span>C3</span>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <ProgressBar
                      animated
                      now={70}
                      style={{ width: "90%" }}
                      label={`${now}%`}
                    />
                  </div>
                </div>
                <div className="ProgressBar_Card_View d-flex">
                  <div className="col-md-2">
                    <img src="\PrimeStudentImages\Green_Icon.png" alt="C1" />
                    {/* <span className="active">C3</span> */}
                  </div>
                  <div className="col-md-10">
                    <p>3.1 Addition and subtraction</p>
                  </div>
                </div>
                <div className="ProgressBar_Card_View d-flex">
                  <div className="col-md-2">
                    <img
                      src="\PrimeStudentImages\Grey_Icon.png"
                      alt="C1"
                      className="Inactive"
                    />
                    {/* <span className="inActive">C3</span> */}
                  </div>
                  <div className="col-md-10">
                    <p>3.2 multiplication and division and etc</p>
                  </div>
                </div>
              </div>
            </div>
            <i onClick={progressbarNextLessons} id="iconScroll_right">
              <FaAngleRight />
            </i>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProgressbarViewAll;
