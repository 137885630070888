import React from "react";
import Leftbar from "./Leftbar";
import Topbar from "./Topbar";

function Navbars() {
  return (
    <div>
      <Leftbar />
      <Topbar />
    </div>
  );
}

export default Navbars;
