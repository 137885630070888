import React from "react";
import Home from "./Home";
import "../Styles/SigninPage.css";

import {Link} from "react-router-dom";
function Signout() {
  return (
    <>
      <div>
        {/* <Navbars/> */}
        <Home />
        {/* <div className="overlay"></div> */}
      </div>
      <div className="signout-card ">
        <div className=" pt-5">
          <h6 className="p-t">Are you sure? you want to sign out?</h6>
          <div className="signout-btn">
          <Link to="/home">
              <button className="button2">No</button>
            </Link>
            <Link to="/">
              <button
                className="button1"
                onClick={() => {
                  sessionStorage.removeItem("primaryStudentToken");
                }}
              >
                Yes
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signout;
