import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/ExtraSelftest.css";
import { MdAccessTimeFilled, MdVolumeUp } from "react-icons/md";
import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";

function Selftest2() {
  const [solutionopen, setSolutionOpen] = useState(false);
  const [buttonOpen, setButtopnOpen] = useState("solution");
  const soltuionhandel = () => {
    setSolutionOpen(true);
  };
  const soltuionbuttonHandel = (e) => {
    setButtopnOpen(e);
  };
  return (
    <body className="postid-36782 ">
      <div className="Selftest_Wrapper">
        <div className="container">
          <div className="col-md-12 d-flex">
            <div className="col-md-2">
              <div className="Selftest_Time">
                <button>
                  <MdAccessTimeFilled />
                  &nbsp;
                  <time>01.00</time>
                </button>
              </div>
            </div>
            <div className="col-md-8">
              <div class="pagination">
                <a href="#" className="blocks correct">
                  1
                </a>
                <a href="#" className="blocks">
                  2
                </a>
                <a href="#" className="blocks">
                  3
                </a>
                <a href="#" className="blocks">
                  4
                </a>
                <a href="#" className="blocks">
                  5
                </a>
                <a href="#" className="blocks">
                  6
                </a>
                <a href="#" className="blocks">
                  7
                </a>
                <a href="#" className="blocks">
                  8
                </a>
                <a href="#" className="blocks">
                  9
                </a>
                <a href="#" className="blocks">
                  10
                </a>
              </div>
            </div>
            <div className="col-md-2">
              <div className="Selftest_Back_button">
                <Link to="/lessonview">
                  {" "}
                  <button>Close</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="Selftest_body">
            <div className="Selftest_Question">
              <p>
                Q1.
                <i>
                  <MdVolumeUp />
                </i>{" "}
                Ben ate half a Pizza
              </p>
              <h5>Which fraction shows the amount he ate</h5>
              <h6>Select the correct box.</h6>

              <img src="\PrimeStudentImages\Half Pizza.svg" alt="C1" />
            </div>
            <div className="Selftest_Options_Solution">
              <button id="Correct_Answer">A. 1/2</button>{" "}
              <button>B. 3/2</button>
            </div>
            <div className="Selftest_Options_Solution">
              <button>C. 3/6</button> <button>D. 2/2</button>
            </div>
            <div className="Close_Button_Selftest_botton d-flex">
              <div id="solution">
                <button onClick={soltuionhandel}>Solution</button>
              </div>
              <div>
                <img src="\PrimeStudentImages\Selftest_tickmark.svg" />
              </div>
              <div>
                {" "}
                <Link to="/selftest2">
                  <button id="Next">Next</button>
                </Link>
              </div>
            </div>
            {solutionopen ? (
              <div className="Solution_View">
                <div className="Close_Avathar">
                  <Link to="/selftest">
                    <i>
                      <MdClose />
                    </i>
                  </Link>
                </div>
                <div className="container d-flex">
                  <div className="Solution_Button ">
                    <button onClick={() => soltuionbuttonHandel("solution")}>
                      Solution
                    </button>
                  </div>
                  <div className="Theory_Button">
                    <button onClick={() => soltuionbuttonHandel("theory")}>
                      Theory
                    </button>
                  </div>
                </div>

                <div className="Solution_Buttons d-flex">
                  {buttonOpen === "solution" ? (
                    <div className="Solution_Tab_Data">
                      <p>The group has 4 cats.</p>
                      <h6>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </h6>
                    </div>
                  ) : (
                    ""
                  )}
                  {buttonOpen === "theory" ? (
                    <div className="Solution_Tab_Data">
                      <h6>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </h6>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="Selftest_bottom_Chapter">
            <span>Chapter 1: 1.1 addition and subtraction</span>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Selftest2;
