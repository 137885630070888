import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";
import RewardsBadges from "../Rewards/RewardsBadges";
import BadgesRewards from "../Rewards/BadgesRewards";
function Badges() {
  const [badgestab, setBadgesTab] = useState("rewards");
  const activatebuttons = () => {
    const button = document.querySelectorAll(".badges");
    function activeLink() {
      button.forEach((item) => item.classList.remove("activate"));
      this.classList.add("activate");
    }
    button.forEach((item) => item.addEventListener("click", activeLink));
  };
  const badgesHandler = (e) => {
    setBadgesTab(e);
  };
  return (
    <>
      <div className="Badges_Wrapper">
        <div className="container-fluid">
          <div className="Badges_buttons">
            <div className="col-md-12 ">
              <div onClick={activatebuttons} className="d-flex">
                <button
                  onClick={() => badgesHandler("rewards")}
                  className="badges activate"
                >
                  {" "}
                  <img src="\PrimeStudentImages\gift_icon.png" alt="" />
                  Rewards
                </button>
                <button
                  onClick={() => badgesHandler("badges")}
                  className="badges"
                >
                  <img src="\PrimeStudentImages\Medal_icon.svg" alt="" />
                  Badges
                </button>
              </div>
            </div>
          </div>
          <div className="badges_top">
            {badgestab === "rewards" ? <RewardsBadges /> : ""}
            {badgestab === "badges" ? <BadgesRewards /> : ""}
          </div>
        </div>
      </div>
    </>
  );
}

export default Badges;
