import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/ContactUs.css";
import { Link } from "react-router-dom";
function ContactUs() {
  const [sendMessage, setSendMessage] = useState(false);
  const sendcontactMessage = () => {
    setSendMessage(true);
  };
  return (
    <>
      <div className="Contactus_Wrapper">
        <div className="container-fluid">
          <h5>Contact Us</h5>
          <div className="col-md-12 d-flex">
            <div className="col-md-6">
              <div className="Contactus_Image">
                <img src="\PrimeStudentImages\Signin_Icon@2x.png" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="Contactus_Send">
                <h5>Send us a message</h5>
                <form className="form-group">
                  <label className="form-label">Your name</label>
                  <input
                    type="text"
                    placeholder="Eg: John Paterson"
                    className="form-control"
                  />
                  <label className="form-label">Your Email</label>
                  <input
                    type="text"
                    placeholder="Eg: John Paterson@gmail.com"
                    className="form-control"
                  />
                  <label className="form-label">Type your querry</label>
                  <textarea
                    class="form-control"
                    placeholder="Type Message"
                    rows="8"
                  />
                  <button onClick={sendcontactMessage}>Send a Message</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        {sendMessage ? (
          <div className="SendMessage_popUp ">
            <div className="col-md-12 d-flex">
              <div className="col-md-6 Tic_Image">
                <img src="\PrimeStudentImages\Tic_Icon.svg" alt="" />
              </div>
              <div className="col-md-6 close_Icon">
                <img src="\PrimeStudentImages\Close_Icon.svg" alt="" />
              </div>
            </div>
            <h6>Thanks for reaching out.</h6>
            <p>Someone from out team will soon get in touch with you.</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default ContactUs;
