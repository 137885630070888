import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";
import { studentNotification } from "../Services";
import Moment from "react-moment";
const calendarStrings = {
  lastDay: "[Yesterday at] LT",
  sameDay: "[Today at] LT",
  nextDay: "[Tomorrow at] LT",
  lastWeek: "[last] dddd [at] LT",
  nextWeek: "dddd [at] LT",
  sameElse: "L",
};
function Notifications() {
  const [notification, setNotification] = useState([]);
  useEffect(() => {
    studentNotification().then((data) => {
      setNotification(data);
      console.log(notification);
    });
  }, []);
  const notificationChange = (e)=>{
    const obj = {
      user_id: notification.user_id,
      name: e.target.name,
      checked: e.target.checked,
    };

  }
  return (
    <div>
      <div className="notifi-content-wrapper">
        <h5 className="mb-3">Notification</h5>
        <div className="row">
          <div className="col-md-8">
            {/* {notification.undefined &&
              notification.map((notify, index) => ( */}
            <div className="notification">
              {notification.length > 0 &&
                notification.map((notify, index) => (
                  <div className="notification__item">
                    <div className="notification__column notification__column--left">
                      <div className="notfy-circle">{notify?.image}</div>
                      <div className="notification__info">
                        {/* <span className="notification__company">John vender</span> */}
                        <span className="notification__title">{notify?.title}</span>
                      </div>
                    </div>
                    <div className="notification__column notification__column--right">
                      <span className="tag">
                        {" "}
                        <Moment calendar={calendarStrings}>
                          {notify?.created_at}
                        </Moment>{" "}
                        
                      </span>
                    </div>
                  </div>
                ))}
              {/* <div className="notification__item bg-grey">
              <div className="notification__column notification__column--left">
                <div className='notfy-circle'>S</div>
                <div className="notification__info">
                
                  <span className="notification__title">New science video has uploaded by Sqillup.</span>
                </div>
              </div>
              <div className="notification__column notification__column--right">
                <span className="tag">
                  few seconds ago
                </span>
              </div>
            </div> */}
              {/* <div className="notification__item">
              <div className="notification__column notification__column--left">
                <div className='notfy-circle yellow-cicle'>S</div>
                <div className="notification__info">
                 
                  <span className="notification__title">There is a New Math Practice test for you.</span>
                </div>
              </div>
              <div className="notification__column notification__column--right">
                <span className="tag">
                  few seconds ago
                </span>
              </div>
            </div> */}
              {/* <div className="notification__item bg-grey">
              <div className="notification__column notification__column--left">
                <div className='notfy-circle'>L</div>
                <div className="notification__info">
                  <span className="notification__company">Lephen Shaw</span>
                  <span className="notification__title">You have completed the week 1 Summer activity of maths.</span>
                </div>
              </div>
              <div className="notification__column notification__column--right">
                <span className="tag">
                  few seconds ago
                </span>
              </div>
            </div> */}
            </div>
            {/* ))} */}
          </div>
          <div className="col-md-4">
            <div className="notification-settigs">
              <h5>Notification Setting</h5>
              <div className="notify-card">
                <p>Payment Notifications</p>

                {/* Push notifications */}
                <div className="switch_box box_1">
                  <input type="checkbox" className="switch_1" checked onClick={notificationChange}/>
                  <label>Push notifications</label>
                </div>
                {/* Push notificationsend */}

                {/* email notifications */}
                <div className="switch_box box_1">
                  <input type="checkbox" className="switch_1" onClick={notificationChange}/>
                  <label>Email notifications</label>
                </div>
                {/* email notifications end */}

                {/* text notifications */}
                <div className="switch_box box_1">
                  <input type="checkbox" className="switch_1" onClick={notificationChange}/>
                  <label>Text notifications</label>
                </div>
                {/* text notifications end */}

                <p>Admin Notifications</p>

                {/* Push notifications */}
                <div className="switch_box box_1">
                  <input type="checkbox" className="switch_1" onClick={notificationChange}/>
                  <label>Push notifications</label>
                </div>
                {/* Push notifications end */}

                {/* email notifications */}
                <div className="switch_box box_1">
                  <input type="checkbox" className="switch_1" onClick={notificationChange}/>
                  <label>Email notifications</label>
                </div>
                {/* email notifications end */}

                {/* text notifications */}
                <div className="switch_box box_1">
                  <input type="checkbox" className="switch_1" onClick={notificationChange}/>
                  <label>Text notifications</label>
                </div>
                {/* text notifications end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
