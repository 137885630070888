import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/MyPracticeTest.css";
import Unicorndog from "../LottieJsonFiles/Unicorndog.json";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
function SubmitForm() {
  
  return (
    <div>
        <div className="Submit_Form">
          <div className="Submit_close_icon">
            <Link to="/practicetest">
              {" "}
              <img src="\PrimeStudentImages\Close_Icon.svg" alt="" />
            </Link>
          </div>
          <div className="unicorn_dog">
            <Lottie animationData={Unicorndog} />
          </div>
          <div className="Submit_Form_Borders">
            <h4>You have successfully completed your activity.</h4>
            <div className="Submit_Form_button">
              <p>Score obtained</p>
              <span>
                <b>18/</b>20
              </span>
            </div>
          </div>
          <div className="Submit_View_solution_Form">
            <Link to="/viewsolution"><button>View Solution &rarr;</button></Link>
          </div>
        </div>
    </div>
  );
}

export default SubmitForm;
