import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Revision.css";

import Selftest from "../Revision/Selftest";
import PastPaper from "../Revision/PastPaper";

const revisionActiveHandler = () => {
  const list = document.querySelectorAll(".test");
  function activeLink() {
    list.forEach((item) => item.classList.remove("active"));
    this.classList.add("active");
  }
  list.forEach((item) => item.addEventListener("click", activeLink));
};

function Revision() {
  const [revision, setrevision] = useState("selftest");
  const revisionbutton = (e) => {
    setrevision(e);
  };
  //Top Button Activation Script

  return (
    <>
      <div className="MyPracticeTest_wrapper">
        <div className="container-fluid">
          <div className="MyPracticeTest_Dropdown">
            <select className=" dropdown-toggle" data-bs-toggle="dropdown">
              <option id="math">Math</option>
              <option id="science">Science</option>
            </select>
          </div>
        </div>
      </div>
      <div className="Revision_tab_Wrapper">
        <div className="Revision_tab_buttons">
          <div className="col-md-12" onClick={revisionActiveHandler}>
            <button
              onClick={() => revisionbutton("selftest")}
              className="test active"
            >
              Selftest
            </button>
            <button
              onClick={() => revisionbutton("pastpaper")}
              className="test"
            >
              Pastpaper
            </button>
          </div>
        </div>
        <div className="revision_Tab_Tables ">
          {revision === "selftest" ? <Selftest /> : ""}
          {revision === "pastpaper" ? <PastPaper /> : ""}
        </div>
      </div>
    </>
  );
}

export default Revision;
