import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Curriculum.css";
import Curriculum from "../Components/Curriculum";
import { Link } from "react-router-dom";
function ChapterGuidline() {
  return (
    <>
      <div className="">
        <Curriculum />
        <div className="overlay"></div>
      </div>

      <div className="Curriculum_Card">
        <div className="Curriculum_guidline_Image">
          <Link to="/curriculum">
            <img src="\PrimeStudentImages\Close_Icon.svg" alt="" />
          </Link>
        </div>
        <div className="guidline_card_imageScreenshot"></div>
      </div>
    </>
  );
}

export default ChapterGuidline;
