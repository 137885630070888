import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Home.css";
import { FaMinus } from "@react-icons/all-files/fa/FaMinus";
import {
  lessonScore,
  listOfSubjects,
  listOfExamtype,
  listOfChapter,
  dashboardCardScore
} from "../Services";
const initialvalues = {
  subject_id: "",
  chapter_id: "",
  lesson_id: "",
  lesson_type_id: "",
};
function ScoreViewAll() {
  const [hideTable, setHideTable] = useState(false);
  const [requestData, setRequestData] = useState(initialvalues);
  const [subjectList, setSubjectList] = useState([]);
  const [examTypeList, setExamType] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [lessonScoreView, setLessonScoreView] = useState([]);
  const [validate, setValidate] = useState();
  const [totalavg,setTotalAvg] = useState([]);
  useEffect(() => {
    listOfSubjects().then((data) => {
      setSubjectList(data);
    });
    listOfExamtype().then((data) => {
      setExamType(data);
    });
    listOfChapter().then((data) => {
      setChapterList(data);
    });
  }, []);
  const listofDropdownHandler = (e) => {
    setRequestData({ ...requestData, [e.target.name]: [e.target.value] });
    setValidate("");
  };
  const showhandelTable = () => {
    if (
      requestData.subject_id &&
      requestData.lesson_type_id &&
      requestData.chapter_id 
    ) {
      lessonScore(requestData).then((data) => {
        setLessonScoreView(data);
        console.warn(data);
        setHideTable(true);
      });
      dashboardCardScore(requestData).then((data)=>{
        setTotalAvg(data);
      })
      setValidate("");
    } else {
      setValidate("Please Select Below data");
    }
  };

  return (
    <div className="ScoreViewAll_Wrapper">
      <div className="container-fluid">
        <div className="col-md-12 d-flex">
          {hideTable ? (
            <div className="col-md-8">
              <div className="view-all-left-dta">
               
                <table className="table">
                  <thead>
                    <th>Lesson Name</th>
                    <th>Score</th>
                  </thead>
                  {lessonScoreView.length > 0 && lessonScoreView.map((lesson,index)=>(
                  <tbody>
                    <br />
                    <tr>
                      <td>
                        <span>{lesson?.lesson?.name}</span>
                      </td>
                      <td>
                        <span>{lesson?.score}</span>
                      </td>
                    </tr>
                    </tbody>
                  ))}
                </table>
                {totalavg.length >0 && totalavg.map((data,index)=>(
                <div className="row view_paragraph">
                  <div className="col-md-6">
                    <span id="view_p1">Total score:{data?.total_score} </span>
                  </div>
                  <div className="col-md-6">
                    <span id="view_p2">Average percentage:{data?.avg}%</span>
                  </div>
                </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="col-md-8">
              <div className="view-all-left-dta">
                <img src="\PrimeStudentImages\Scorecat_Icon.svg" alt="" />
                <h6>To check the score select the given fields.</h6>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadips elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat.
                </p>
              </div>
            </div>
          )}
          <div className="col-md-4">
            <div className="view-all-right-dta ">
            <span
                  style={{ color: "red", fontWeight: "bold" }}
                  className="validate-Dropdown"
                >
                  {" "}
                  {validate}
                </span>
              <select
                className="dropdown-toggle"
                name="subject_id"
                onChange={listofDropdownHandler}
              >
                <option>Select Subject</option>
                {subjectList.length &&
                  subjectList.map((subject, index) => (
                    <option value={subject?.id}>{subject?.name}</option>
                  ))}
              </select>
              <select
                className="dropdown-toggle"
                name="lesson_type_id"
                onChange={listofDropdownHandler}
              >
                <option>Select Exam type</option>
                {examTypeList.length &&
                  examTypeList.map((examtype, index) => (
                    <option value={examtype?.id}>{examtype?.title}</option>
                  ))}
              </select>

              <select
                className="dropdown-toggle"
                name="chapter_id"
                onChange={listofDropdownHandler}
              >
                <option>Select Chapter</option>
                {chapterList.length &&
                  chapterList.map((chapter, index) => (
                    <option value={chapter?.id}>{chapter?.name}</option>
                  ))}
              </select>

              <div className="view-all-right-button">
                {hideTable ? (
                  <button
                    className="btn"
                    disabled
                    data-bs-toggle="button"
                    style={{ backgroundColor: "#A2A2A2" }}
                  >
                    Apply
                  </button>
                ) : (
                  <button className="btn" onClick={showhandelTable}>
                    Apply
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScoreViewAll;
