import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Seasonactivity.css";
import Summer from "../SeasonActivity/Summer";
import Autumn from "../SeasonActivity/Autumn";
import Winter from "../SeasonActivity/Winter";
import Spring from "../SeasonActivity/Spring";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function SeasonActivity() {
  const [sesons, setSeasons] = useState("summer");

  var d = new Date();
  var monthName = months[d.getMonth()];

  const sesonshandel = (e) => {
    setSeasons(e);
  };
  const seasonActive = () => {
    const listsesaon = document.querySelectorAll(".season");
    function activeLink() {
      listsesaon.forEach((item) => item.classList.remove("active"));
      this.classList.add("active");
    }
    listsesaon.forEach((item) => item.addEventListener("click", activeLink));
  };

  return (
    <>
      <div className="SeasonActivity_Wrapper">
        <div className="container-fluid">
          <div className="SeasonActivity_Dropdown">
            <select className="dropdown-toggle" data-bs-toggle="dropdown">
              <option id="math">Math</option>
              <option id="science">Science</option>
            </select>
          </div>
          <div className="Sesons_data">
            <div className="col-md-12 d-flex">
              <div className="col-md-8 d-flex">
                <div className="Seasons_Buttons" onClick={() => seasonActive()}>
                  <button
                    onClick={() => sesonshandel("summer")}
                    id="summer"
                    className="season active"
                  >
                    Summer
                  </button>
                  <button
                    onClick={() => sesonshandel("autumn")}
                    id="autumn"
                    className="season"
                  >
                    Autumn
                  </button>
                  <button
                    onClick={() => sesonshandel("winter")}
                    id="winter"
                    className="season"
                  >
                    Winter
                  </button>
                  <button
                    onClick={() => sesonshandel("spring")}
                    id="spring"
                    className="season"
                  >
                    Spring
                  </button>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="Season_top_right d-md-flex justify-content-md-end">
                  <img src="\PrimeStudentImages\Left_arrow.svg" alt="" />
                  <input value={monthName} />
                  <img src="\PrimeStudentImages\right_arrow.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Season_Activities">
        {sesons === "summer" ? <Summer /> : ""}
        {sesons === "autumn" ? <Autumn /> : ""}
        {sesons === "winter" ? <Winter /> : ""}
        {sesons === "spring" ? <Spring /> : ""}
      </div>
    </>
  );
}

export default SeasonActivity;
