import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Revision.css";
import { MdOutlineEventNote } from "react-icons/md";
import { Link } from "react-router-dom";

function Selftest() {
  const [notes, setNotes] = useState(false);
  const notesPopUP = () => {
    setNotes(true);
  };
  const closenotes = () => {
    setNotes(false);
  }
  return (
    <div className="Revision_tab_">
      <div className="col-md-12 d-flex">
        <div className="col-md-2">
          <div className="Revision_Lesson_List">
            <ul>
              <li className="active">1. Patterns</li>
              <li className="list">2. Classify</li>
              <li className="list">3. Counting Numbers</li>
              <li className="list">4. Positions</li>
              <li className="list">5. Sorting</li>
              <li className="list">6. Numbers System</li>
              <li className="list">7. 3D and 2D</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="Revision_Chapter_table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Lesson Code</th>
                  <th scope="col">Lesson</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.1</td>
                  <td>Introduction</td>
                  <td>Introduction about Patterns</td>
                </tr>
                <tr>
                  <td>1.2</td>
                  <td>How patterns works</td>
                  <td>Lorem ipsum dolor sit ,lorum</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-1">
          <div className="Revision_Chapter_table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Com</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>
                      <img src="\PrimeStudentImages\Tickmark.svg" alt="" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <img src="\PrimeStudentImages\Tickmark.svg" alt="" />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-1">
          <div className="Revision_Chapter_table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Redo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Link to="/revisionselftest">
                      {" "}
                      <button>Start</button>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link to="/revisionselftest">
                      {" "}
                      <button>Start</button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-1">
          <div className="Revision_Chapter_table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>19</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>09</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-1">
          <div className="Revision_Chapter_table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i onClick={() => notesPopUP()}>
                      <MdOutlineEventNote />
                    </i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i>
                      <MdOutlineEventNote />
                    </i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {notes ? (
        <div className="notes_pop_up">
          <img
            src="\PrimeStudentImages\Close_Icon.svg"
            alt=""
            onClick={() => closenotes()}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Selftest;
