import React from "react";
import Leftbar from "../Swap/Leftbar";
import Topbar from "../Swap/Topbar";
function Swap() {
  return (
    <>
      {" "}
      <div>
        <Leftbar />
        <Topbar />
      </div>
      <div>
        <div className="Content_Home_Wrapper">
          <div className="container-fluid">
            <div className="Home_page_Wrapper">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-8 col-xs-4 d-flex">
                  <div className="col-lg-8 col-md-8 col-sm-4 col-xs-4">
                    <div className="Greetings">
                      <h5>Good Morning,<strong>Petersan</strong></h5>
                    </div>
                    <div className="Daily_Notifications_Image">
                      <img
                        src="\PrimeStudentImages\Daily_Notifications_Icon.jpg"
                        alt=""
                      />
                      <div className="Notices_home">
                        <h4>Notices Comes Here</h4>
                        <div className="col-md-5">
                          <p>
                            Non quam lacus suspendisse faucibus interdum posuere
                            lorem. Risus ultricies tristique nulla aliquet enim
                            tortor at. Auctor urna nunc id cursus.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Swap;
