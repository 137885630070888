import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/Revision.css";
import { MdOutlineEventNote } from "react-icons/md";
import { Link } from "react-router-dom";

function PastPaper() {
  const [pastnotes, setPastNotes] = useState(false);
  const pastpaperNotes = () => {
    setPastNotes(true);
  };
  const closepastnotes = () =>{
    setPastNotes(false);
  }
  return (
    <>
      <div className="Revision_tab_">
        <div className="col-md-12 d-flex">
          <div className="col-md-8">
            <div className="Revision_pastpaper_Chapter_table">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Chapter</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1. Patterns</td>
                  </tr>
                  <tr>
                    <td>2. Classify</td>
                  </tr>
                  <tr>
                    <td>3. Counting Numbers</td>
                  </tr>
                  <tr>
                    <td>4. Position</td>
                  </tr>
                  <tr>
                    <td>5. Sorting</td>
                  </tr>
                  <tr>
                    <td>6. Number System</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-1">
            <div className="Revision_Chapter_table">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Com</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span>
                        <img src="\PrimeStudentImages\Tickmark.svg" alt="" />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        <img src="\PrimeStudentImages\Tickmark.svg" alt="" />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-1">
            <div className="Revision_Chapter_table">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Redo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Link to="/revisionselftest">
                        {" "}
                        <button>Start</button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Link to="/revisionselftest">
                        {" "}
                        <button>Start</button>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-1">
            <div className="Revision_Chapter_table">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span>19</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>09</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-1">
            <div className="Revision_Chapter_table">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <i onClick={() => pastpaperNotes()}>
                        <MdOutlineEventNote />
                      </i>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i>
                        <MdOutlineEventNote />
                      </i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {pastnotes ? (
          <div className="notes_pop_up">
            <img
              src="\PrimeStudentImages\Close_Icon.svg"
              alt=""
              onClick={() => closepastnotes()}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default PastPaper;
